import { Link } from 'react-router-dom';
import Navbar from '../Navbar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { useState, useEffect } from 'react';

function CountryDropdown({ selectedCountry, onCountryChange }) {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  

  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/state`);
        const json = await response.json();
        if (json.status === 'SUCCESS') {
          setCountries(json.data);
        } else {
          setError('Failed to fetch countries');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchCountries();
  }, []);

  if (error) return <div>Error: {error}</div>;
  if (loading) return <div>Loading...</div>;

  return (
    <select className="custom-select" style={{ borderRadius: "25px" }} value={selectedCountry} onChange={onCountryChange}>
      {countries.map(country => (
        <option key={country.id} value={country.id}>
          {country.name} - {country.subcountry}
        </option>
      ))}
    </select>
  );
}


function SignUpFormCreator() {
    const [formData, setFormData] = useState({
        companyName: '',
        email: '',
        address: '',
        user_role:1,
        countryId:"",
        phone_no:""
    });
    const [selectedCountryId, setSelectedCountryId] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleCountryChange = (e) => {
        setFormData({
            ...formData,
            countryId: e.target.value
        });
    };
      

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // console.log('Form data submitted:', formData);
    //     // Add form submission logic here
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Prepare data to match API's expected format
        const postData = {
            email: formData.email,
            name: formData.companyName,
            user_role: formData.user_role,
            state: formData.countryId,
            mobile_number: formData.phone_no,
            company: formData.companyName
        };

        // Send data to the API endpoint using fetch
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/registration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            const responseData = await response.json();
            // console.log('Server Response:', responseData);
            if (responseData.status === 'FAILURE') {
                // Extract error messages and show toast
                const errorMessages = responseData.additional_message.map(msg => 
                    Object.values(msg).join(', ')
                ).join('; ');
        
                toast.error(`Error!`);
            } else {
                // Handle success scenario
                toast.success('Registration successful!');
            }
        } catch (error) {
            toast.error('Error submitting form!');
            console.error('Error submitting form:', error);
        } 
    };

    return (
        <div>
            <Navbar text={"#101531"} textColor={"white"}  photo={"embassy_white_logo.png"} />
            <div className="container-fluid text-center py-5"
                style={{ background: '#101531', color: 'white', padding: '20px', margin: 'auto', height: '90.5vh', display: 'flex', flexDirection: 'column' }}>
                <h2 className='mb-4'>Creators are Welcome!</h2>
                <div className="row justify-content-center">
                    <form className="col-md-3 " onSubmit={handleSubmit}>
                        <div className="form-group text-left">
                            <label style={{ textAlign: 'left', fontSize: "`1.3rem" }} className="mb-0 ml-2">Name</label>
                            <input type="text" className="form-control" name="companyName" value={formData.companyName} onChange={handleChange} style={{ borderRadius: "25px" }} />
                        </div>
                        <div className="form-group text-left">
                            <label style={{ textAlign: 'left', fontSize: "`1.3rem" }} className="mb-0 ml-2">Email</label>
                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} style={{ borderRadius: "25px" }} />
                        </div>
                        <div className="form-group text-left">
                            <label style={{ textAlign: 'left', fontSize: "`1.3rem" }} className="mb-0 ml-2">Address</label>
                            <input type="text" className="form-control" name="address" value={formData.address} onChange={handleChange} style={{ borderRadius: "25px" }} />
                        </div>
                        <div className="form-group text-left">
                            <label style={{ textAlign: 'left', fontSize: "`1.3rem" }} className="mb-0 ml-2">Phone </label>
                            <input type="number" className="form-control" name="phone_no" value={formData.phone_no} onChange={handleChange} style={{ borderRadius: "25px" }} />
                        </div>
                        <div className="form-group text-left">
                            {/* <select className="custom-select">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select> */}
                            <label style={{ textAlign: 'left', fontSize: "`1.3rem" }} className="mb-0 ml-2">State</label>
                            {/* <CountryDropdown  /> */}
                            {/* <CountryDropdown selectedCountryId={selectedCountryId} onSelectedCountryChange={handleCountryChange} /> */}
                            <CountryDropdown selectedCountry={formData.countryId} onCountryChange={handleCountryChange} />

                        </div>
                        <div className="form-group ">
                            <button className='btn btn-lg mt-3' type="submit" style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                <span style={{
                                    color: '#fff',
                                    lineHeight: 'inherit',
                                    cursor: 'pointer',
                                    // backgroundColor: '#3898ec',
                                    border: '0',
                                    borderRadius: '0',
                                    padding: '9px 15px',
                                    textDecoration: 'none',
                                    display: 'inline-block'
                                }}>
                                    Create Account
                                </span>
                            </button>


                            {/* <button type="submit" style={{ backgroundColor: '#A267AC', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', marginTop: '20px' }}>Create Account</button> */}
                        </div>
                        <div className="form-group ">
                            <small className='text-center' style={{ textAlign: 'center', marginTop: '20px', fontWeight: "600" }}>
                                Already have an account? <br />  Click <Link to="/signin" style={{ color: '#A267AC' }}>here </Link>to login.
                            </small>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );


}

export default SignUpFormCreator;