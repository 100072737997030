import React from 'react'
import "./Apiloading_animation.css";

export default function Apiloading_animation() {
	return (
		<div>
			<div class="container">
				<div class="loading-wave">
					<div class="loading-bar"></div>
					<div class="loading-bar"></div>
					<div class="loading-bar"></div>
					<div class="loading-bar"></div>
				</div>

			</div>

		</div>
	)
}
