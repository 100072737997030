// ModalComponent.js
import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalComponent = ({
  show,
  onHide,
  title,
  bodyContent,
  footerContent,
  bodyClass,
  customClass,
  showSliderBtn = false,
  handleNext,
  handlePrev,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={customClass}
      style={{
        borderRadius: "25px", // Custom border radius for the modal
        border: "none", // Removing default border,
      }}
    >
      {showSliderBtn && (
        <>
          <div
            onClick={() => handlePrev()}
            style={{
              position: "absolute",
              top: "50%",
              cursor: "pointer",
              width: "40px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              background: "#d2d2d2",
              border: "2px solid white",
              borderRadius: "100%",
              left: "-240px",
            }}
          >
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </div>
          <div
            onClick={() => handleNext()}
            style={{
              position: "absolute",
              top: "50%",
              cursor: "pointer",
              width: "40px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              background: "#d2d2d2",
              border: "2px solid white",
              borderRadius: "100%",
              right: "-240px",
            }}
          >
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      )}
      {title && (
        <Modal.Header>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{title}</span>
            <i
              onClick={() => onHide()}
              class="fa fa-times-circle"
              style={{ color: "#120c34", fill: "#120c34", cursor: "pointer" }}
              aria-hidden="true"
            ></i>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className={bodyClass}>{bodyContent}</Modal.Body>
      {/* <Modal.Footer>
        {footerContent ? footerContent : (
          <>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={onHide}>
              Save changes
            </Button>
          </>
        )}
      </Modal.Footer> */}
    </Modal>
  );
};

export default ModalComponent;
