import { format, parse } from "date-fns";

export function convertToAmPm(timeStr) {
    // Split the input time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeStr.split(':');
  
    // Convert hours to number to handle AM/PM conversion
    let hour = parseInt(hours);
    const isPM = hour >= 12;
  
    // Convert hour to 12-hour format
    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12; // Midnight case
    }
  
    // Determine AM or PM
    const period = isPM ? 'PM' : 'AM';
  
    // Return the formatted time
    return `${hour}:${minutes} ${period}`;
  }
  

  export function formatDate(dateStr) {
    // Parse the input date string (dd/mm/yyyy)
    const [day, month, year] = dateStr.split('/');
    const date = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
  
    // Format the date into 'dd MMMM yyyy' format
    return format(date, 'd MMMM yyyy');
  }