import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LimitedOffer from "../Dashboad/LimitedOffer";

const ProfileManageModal = () => {
  const [nextState, setNextState] = useState(false);
  const [addFunds, setAddFunds] = useState("");

  const handleCheckout = async (event) => {
    event.preventDefault();

    const stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
    const stripe = await stripePromise;
    const isLogedin = window.localStorage.getItem("accessToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/auth/v1/checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isLogedin}`,
          },
          body: JSON.stringify({ amount: parseFloat(addFunds) }), // Convert to cents
        }
      );

      const {
        data: { id: sessionId },
      } = await response.json();
      console.log("sessionId: ", sessionId);

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.error("Checkout error:", error);
    }
  };

  return (
    <div>
      {nextState ? (
        <LimitedOffer handleBack={() => setNextState(false)} />
      ) : (
        <>
          <div className="container-fluid text-center">
            <h5
              className="text-center set_font_mainPage mb-4"
              style={{ color: "#120c34" }}
            >
              add funds
            </h5>
            <h4 className="text-center mt-3" style={{ color: "#120c34" }}>
              how much would you like to add?
            </h4>
          </div>
          <div className="row">
            <div className="col-md-12 mt-1 text-center">
              <div
                style={{
                  position: "relative",
                  width: "30%",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "x-large",
                  }}
                >
                  $
                </span>
                <input
                  type="number"
                  onChange={(e) => setAddFunds(e.target.value)}
                  style={{
                    border: "#120c34 2px solid",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    paddingLeft: "30px",
                  }}
                  placeholder="add funds"
                  className="input-box rounded-pill p-2 text-center"
                  min="100"
                  value={addFunds}
                />
              </div>
            </div>
          </div>
          <h5 className="text-center mt-3" style={{ color: "#120c34" }}>
            Save up to $800 by{" "}
            <Link
              style={{ color: "#90b5e9" }}
              onClick={() => setNextState(true)}
            >
              Subscribe today
            </Link>
          </h5>
          <h4 className="text-center mt-5" style={{ color: "#120c34" }}>
            Note: <br />
            These funds will be added to your budget, but are only spent as
            participants genrate clicks.
          </h4>

          <div className="text-center mt-4">
            <button
              onClick={handleCheckout}
              className="btn btn-lg"
              style={{
                letterSpacing: "-.02em",
                textTransform: "none",
                backgroundColor: "#3898ec",
                backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                borderRadius: "25px",
                fontFamily: "sofia-pro,sans-serif",
                fontSize: "1.2em",
                fontWeight: 600,
                lineHeight: 1,
                transition: "transform .3s",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  lineHeight: "inherit",
                  cursor: "pointer",
                  // backgroundColor: '#3898ec',
                  border: "0",
                  borderRadius: "0",
                  padding: "9px 15px",
                  textDecoration: "none",
                  display: "inline-block",
                }}
              >
                continue to payment
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileManageModal;
