import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ViewPage = () => {
    const { unique_code } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [ip, setIp] = useState('');
    const [domain, setDomain] = useState(window.location.hostname);
    const [key, setKey] = useState(unique_code);

    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIp(data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        fetchIPAddress();
    }, []);

    useEffect(() => {
        const sendDataToApi = async () => {
            if (!ip || !key) return; // Ensure IP and key are set before making the request
    
            try {
                const accessToken = window.localStorage.getItem("accessToken");
                if (!accessToken) {
                    console.error('No access token found');
                    setLoading(false);
                    return;
                }
    
                const response = await fetch(`https://api.embassy.io/api/view/v1/url`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({ ip, domain, key })
                });
    
                const data = await response.json();
                if (data && data.status === "SUCCESS" && data.data && data.data.url) {
                    // Ensure the URL is absolute
                    const url = data.data.url.startsWith('http') ? data.data.url : `http://${data.data.url}`;
                    window.location.href = url; // Redirect to the URL received from the backend
                } else {
                    setLoading(false); // Stop loading if the URL isn't provided
                }
            } catch (error) {
                console.error('Error sending data to API:', error);
                setLoading(false); // Stop loading if there's an error
            }
        };
    
        if (ip && domain && key) {
            sendDataToApi();
        }
    }, [ip, domain, key, unique_code]);

    if (loading) {
        return <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}> <img className='img-fluid' src="/embassy_logo.png" alt="" /></div>; // Display loading indicator
    }

    return null; // Render nothing once loading is complete (if the redirect didn't happen)
};

export default ViewPage;
