import React from "react";

const Errors = ({ msg, mid = false }) => {
  return (
    <div
      style={{
        color: "red",
        fontSize: "14px",
        textAlign: mid ? "center" : "left",
      }}
    >
      {msg}
    </div>
  );
};

export default Errors;
