import React, { useEffect, useState } from "react";
import Select from "react-select";

const MultiSelectDropdown = ({
  data,
  placeholder,
  handleChange,
  value,
  multi = false,
  styleDesign,
  empty = false,
}) => {
  // Add an empty option to the data
  const emptyOption = { value: "", label: "None" }; // You can customize the label as needed
  const optionsWithEmpty = empty ? [emptyOption, ...data] : data; // Prepend the empty option to the options

  return (
    <div>
      <Select
        value={value}
        onChange={handleChange}
        options={optionsWithEmpty} // Use the updated options with the empty field
        isMulti={multi}
        placeholder={placeholder}
        styles={{
          control: (base) => ({
            ...base,
            border: "2px solid #120c34",
            borderRadius: "25px",
            fontSize: "x-large",
            width: "100%",
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: "#f0f0f0",
            borderRadius: "15px",
            padding: "5px",
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: "#333",
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: "#333",
            ":hover": {
              backgroundColor: "#ddd",
              color: "#120c34",
            },
          }),
          ...styleDesign,
        }}
        className="input-box rounded-pill text-center"
      />
      <input
        type="text"
        style={{
          border: "#120c34 2px solid",
          borderRadius: "25px",
          fontSize: "x-large",
          width: "100%",
          display: "none",
        }}
        readOnly
        placeholder="ex: Los Angeles, CA"
        className="input-box rounded-pill text-center"
      />
    </div>
  );
};

export default MultiSelectDropdown;
