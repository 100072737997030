import React from "react";
import "./Animation_Custom.css";

export default function Animation_Custom() {
  return (
    <div style={{ marginBottom: "60px" }}>
      <div class="custom-container">
        <div class="custom-loading-wave">
          <div class="custom-loading-bar"></div>
          <div class="custom-loading-bar"></div>
          <div class="custom-loading-bar"></div>
          <div class="custom-loading-bar"></div>
        </div>
      </div>
    </div>
  );
}
