import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalComponent from "../../CommonComponents/ModalComponent";
import EditMarketplaceRequest from "./EditMarketplaceRequest";
import {
  convertToAmPm,
  formatDate,
} from "../../CommonComponents/CommonFunction";
import CustomButton from "../../CommonComponents/CustomButton";
import Animation_Custom from "../AnimationCustom/Animation_Custom";

const DateTimeComponent = ({ isoString }) => {
  // Step 1: Parse the ISO 8601 string to a Date object
  // console.log(isoString)
  const date = new Date(isoString);

  // Check if the date is valid
  if (isNaN(date)) {
    return <p>Invalid Date</p>;
  }

  // Step 2: Format the date and time components together
  const formattedTime = date.toLocaleTimeString();

  // Step 3: Format the date component to dd/mm/yyyy
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  const formattedDateTime = `${convertToAmPm(formattedTime)} - ${formatDate(
    formattedDate
  )}`;

  return formattedDateTime;
};

function CampaignCards({ onCardSelect }) {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [next, setNext] = useState(null); // Track current page
  const componentRef = useRef(null);

  const handleCardClick = (id) => {
    // If the same card is already selected, return early without updating the state
    if (selectedCardId === id) {
      return;
    }

    // Toggle selection: if a different card is clicked, select the new one
    setSelectedCardId(id);
  };

  const updateData = (newData) => {
    setCampaigns((prevData) => {
      // Check if the last item of the current content is the same as the last item of the new data
      const lastCurrentItem = prevData[prevData.length - 1];
      const lastNewItem = newData[newData.length - 1];

      // Compare the last items by relevant properties (e.g., id, title, body)
      const isSame =
        lastCurrentItem?.id === lastNewItem?.id &&
        lastCurrentItem?.title === lastNewItem?.title &&
        lastCurrentItem?.body === lastNewItem?.body;

      // If the last items are not the same, append the new data
      if (!isSame) {
        return [...prevData, ...newData];
      }

      // Return the previous state if the last items are the same
      return prevData;
    });
  };

  const fetchCampaigns = async (page) => {
    setLoading(true);
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?page=${page}&page_size=10&campaign_type=1,2&status=2`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        updateData(data.data.results);
        setNext(data.data.next);
        setLoading(false);
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    const component = componentRef.current;
    if (!component) return; // If the component reference is not set, do nothing

    // Get the component's bounding rectangle
    const { bottom } = component.getBoundingClientRect();

    // Check if the bottom of the component is visible within the viewport
    const isAtBottom = bottom <= window.innerHeight;

    // If the component's bottom is visible and not loading, and there's more data to load
    if (isAtBottom && !loading && next) {
      setPage((prevPage) => prevPage + 1); // Increment page number
    }
  };

  // Fetch initial data on component mount
  useEffect(() => {
    fetchCampaigns(page);
  }, [page, onCardSelect]); // Fetch data whenever page changes

  useEffect(() => {
    if (campaigns.length > 0) {
      // Automatically select the first campaign
      const firstCampaign = campaigns[0];
      setSelectedCardId(firstCampaign.id);
      onCardSelect(firstCampaign);
    }
  }, [campaigns]);

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  return (
    <div ref={componentRef} style={{ height: "70vh" }}>
      {campaigns?.length &&
        campaigns?.map((campaign, index) => (
          <div
            key={campaign.id}
            className="card"
            style={{
              border:
                selectedCardId === campaign.id ? "none" : "#120c34 2px solid",
              borderLeft: "none",
            }}
            onClick={() => {
              handleCardClick(campaign.id);
              onCardSelect(campaign);
            }}
          >
            <div className="card-body my-2 py-0">
              <div className="row">
                <div className="col-md-2">
                  <img
                    src={campaign.creator?.images?.[0] || "/images/sample1.png"}
                    alt=""
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "#120c34 3px solid",
                    }}
                  />
                </div>
                <div className="col-md-10" style={{ cursor: "pointer" }}>
                  <div style={{ textAlign: "start" }}>
                    <div style={{ marginBottom: "10px" }}>
                      {" "}
                      <h4
                        style={{
                          color: "#120c34",
                          fontWeight: "bold",
                        }}
                      >
                        {campaign.creator?.name || "Unknown"}
                      </h4>
                    </div>
                    <div>
                      <h6 style={{ color: "#120c34" }}>
                        <i>
                          {" "}
                          <DateTimeComponent isoString={campaign.updated_at} />
                        </i>
                      </h6>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {loading && (
        <div className="container d-flex justify-content-center align-items-center">
          <Animation_Custom />
        </div>
      )}
    </div>
  );
}
const AcceptCampaignButton = ({ campaign }) => {
  const handleAcceptCampaign = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No access token found");
      return;
    }

    try {
      const response = await fetch(
        `https://api.embassy.io/api/campaign/v1/campaign/accept/${campaign.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Campaign accepted:', data);
      toast.success("Campaign accepted ");
      window.location.reload();
    } catch (error) {
      console.error("Failed to accept campaign:", error);
      toast.error("Error! ");
    }
  };

  return (
    <button
      onClick={handleAcceptCampaign}
      className="btn bg-transparent"
      style={{
        backgroundColor: "#3898ec",
        backgroundImage: "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
        color: "white",
        borderRadius: "25px",
        fontSize: "x-large",
      }}
    >
      Accept request
    </button>
  );
};

const RejectCampaignButton = ({ campaign }) => {
  const handleRejectCampaign = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No access token found");
      return;
    }

    try {
      const response = await fetch(
        `https://api.embassy.io/api/campaign/v1/campaign/reject/${campaign.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Campaign Rejected:', data);
      toast.warning("Campaign Rejected ");
      window.location.reload();
    } catch (error) {
      console.error("Failed to accept campaign:", error);
      toast.error("Error! ");
    }
  };

  return (
    <button
      onClick={handleRejectCampaign}
      className="btn bg-transparent"
      style={{
        border: "#120c34 3px solid",
        borderRadius: "25px",
        fontSize: "x-large",
      }}
    >
      Reject request
    </button>
  );
};

function CampaignCardsInProgress({ onCardSelect }) {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [next, setNext] = useState(null); // Track current page
  const componentRef = useRef(null);

  const handleCardClick = (id) => {
    // If the same card is already selected, return early without updating the state
    if (selectedCardId === id) {
      return;
    }

    // Toggle selection: if a different card is clicked, select the new one
    setSelectedCardId(id);
  };

  const updateData = (newData) => {
    setCampaigns((prevData) => {
      // Check if the last item of the current content is the same as the last item of the new data
      const lastCurrentItem = prevData[prevData.length - 1];
      const lastNewItem = newData[newData.length - 1];

      // Compare the last items by relevant properties (e.g., id, title, body)
      const isSame =
        lastCurrentItem?.id === lastNewItem?.id &&
        lastCurrentItem?.title === lastNewItem?.title &&
        lastCurrentItem?.body === lastNewItem?.body;

      // If the last items are not the same, append the new data
      if (!isSame) {
        return [...prevData, ...newData];
      }

      // Return the previous state if the last items are the same
      return prevData;
    });
  };

  const fetchCampaigns = async (page) => {
    setLoading(true);
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?page=${page}&page_size=10&campaign_type=1,2&status=3`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        updateData(data.data.results);
        setNext(data.data.next);
        setLoading(false);
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    const component = componentRef.current;
    if (!component) return; // If the component reference is not set, do nothing

    // Get the component's bounding rectangle
    const { bottom } = component.getBoundingClientRect();

    // Check if the bottom of the component is visible within the viewport
    const isAtBottom = bottom <= window.innerHeight;

    // If the component's bottom is visible and not loading, and there's more data to load
    if (isAtBottom && !loading && next) {
      setPage((prevPage) => prevPage + 1); // Increment page number
    }
  };

  // Fetch initial data on component mount
  useEffect(() => {
    fetchCampaigns(page);
  }, [page, onCardSelect]); // Fetch data whenever page changes

  useEffect(() => {
    if (campaigns.length > 0) {
      // Automatically select the first campaign
      const firstCampaign = campaigns[0];
      setSelectedCardId(firstCampaign.id);
      onCardSelect(firstCampaign);
    }
  }, [campaigns]);

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  return (
    <div ref={componentRef} style={{ height: "70vh" }}>
      {campaigns?.length &&
        campaigns?.map((campaign, index) => (
          <div
            key={campaign.id}
            className="card"
            style={{
              border:
                selectedCardId === campaign.id ? "none" : "#120c34 2px solid",
              borderLeft: "none",
            }}
            onClick={() => {
              handleCardClick(campaign.id);
              onCardSelect(campaign);
            }}
          >
            <div className="card-body my-2 py-0">
              <div className="row">
                <div className="col-md-2">
                  <img
                    src={campaign.creator?.images?.[0] || "/images/sample1.png"}
                    alt=""
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "#120c34 3px solid",
                    }}
                  />
                </div>
                <div className="col-md-10" style={{ cursor: "pointer" }}>
                  <div style={{ textAlign: "start" }}>
                    <div style={{ marginBottom: "10px" }}>
                      {" "}
                      <h4
                        style={{
                          color: "#120c34",
                          fontWeight: "bold",
                        }}
                      >
                        {campaign.creator?.name || "Unknown"}
                      </h4>
                    </div>
                    <div>
                      <h6 style={{ color: "#120c34" }}>
                        <i>
                          {" "}
                          <DateTimeComponent isoString={campaign.updated_at} />
                        </i>
                      </h6>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {loading && (
        <div className="container d-flex justify-content-center align-items-center">
          <Animation_Custom />
        </div>
      )}
    </div>
  );
}

function CampaignCardsCompleted({ onCardSelect }) {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track current page
  const [next, setNext] = useState(null); // Track current page
  const componentRef = useRef(null);

  const handleCardClick = (id) => {
    // If the same card is already selected, return early without updating the state
    if (selectedCardId === id) {
      return;
    }

    // Toggle selection: if a different card is clicked, select the new one
    setSelectedCardId(id);
  };

  const updateData = (newData) => {
    setCampaigns((prevData) => {
      // Check if the last item of the current content is the same as the last item of the new data
      const lastCurrentItem = prevData[prevData.length - 1];
      const lastNewItem = newData[newData.length - 1];

      // Compare the last items by relevant properties (e.g., id, title, body)
      const isSame =
        lastCurrentItem?.id === lastNewItem?.id &&
        lastCurrentItem?.title === lastNewItem?.title &&
        lastCurrentItem?.body === lastNewItem?.body;

      // If the last items are not the same, append the new data
      if (!isSame) {
        return [...prevData, ...newData];
      }

      // Return the previous state if the last items are the same
      return prevData;
    });
  };

  const fetchCampaigns = async (page) => {
    setLoading(true);
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?page=${page}&page_size=10&campaign_type=1,2&status=4`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        updateData(data.data.results);
        setNext(data.data.next);
        setLoading(false);
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    const component = componentRef.current;
    if (!component) return; // If the component reference is not set, do nothing

    // Get the component's bounding rectangle
    const { bottom } = component.getBoundingClientRect();

    // Check if the bottom of the component is visible within the viewport
    const isAtBottom = bottom <= window.innerHeight;

    // If the component's bottom is visible and not loading, and there's more data to load
    if (isAtBottom && !loading && next) {
      setPage((prevPage) => prevPage + 1); // Increment page number
    }
  };

  // Fetch initial data on component mount
  useEffect(() => {
    fetchCampaigns(page);
  }, [page, onCardSelect]); // Fetch data whenever page changes

  useEffect(() => {
    if (campaigns.length > 0) {
      // Automatically select the first campaign
      const firstCampaign = campaigns[0];
      setSelectedCardId(firstCampaign.id);
      onCardSelect(firstCampaign);
    }
  }, [campaigns]);

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  return (
    <div ref={componentRef} style={{ height: "70vh" }}>
      {campaigns?.length
        ? campaigns?.map((campaign, index) => (
            <div
              key={campaign.id}
              className="card"
              style={{
                border:
                  selectedCardId === campaign.id ? "none" : "#120c34 2px solid",
                borderLeft: "none",
              }}
              onClick={() => {
                handleCardClick(campaign.id);
                onCardSelect(campaign);
              }}
            >
              <div className="card-body my-2 py-0">
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src={
                        campaign.creator?.images?.[0] || "/images/sample1.png"
                      }
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        border: "#120c34 3px solid",
                      }}
                    />
                  </div>
                  <div className="col-md-10" style={{ cursor: "pointer" }}>
                    <div style={{ textAlign: "start" }}>
                      <div style={{ marginBottom: "10px" }}>
                        {" "}
                        <h4
                          style={{
                            color: "#120c34",
                            fontWeight: "bold",
                          }}
                        >
                          {campaign.creator?.name || "Unknown"}
                        </h4>
                      </div>
                      <div>
                        <h6 style={{ color: "#120c34" }}>
                          <i>
                            {" "}
                            <DateTimeComponent
                              isoString={campaign.updated_at}
                            />
                          </i>
                        </h6>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : !loading && (
            <div
              style={{ paddingTop: "30px" }}
              className="container d-flex justify-content-center align-items-center"
            >
              no data found{" "}
            </div>
          )}
      {loading && (
        <div className="container d-flex justify-content-center align-items-center">
          <Animation_Custom />
        </div>
      )}
    </div>
  );
}

const CampaignDetails_Check_Approval = ({ campaign, handleOpen }) => {
  // State to manage the API result
  const [apiResult, setApiResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const cancelRequest = async () => {
    setLoading(true);
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/leave/${campaign.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        setLoading(false);
        toast.success(data?.message);
        window.location.reload();
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  const acceptRequest = async () => {
    setLoading(true);
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/accept/${campaign.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        setLoading(false);
        toast.success(data?.message);
        window.location.reload();
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
    setLoading(false);
  };

  const fetchApiResult = async () => {
    try {
      setApiResult(campaign.counter);
    } catch (error) {
      console.error("Error fetching API result:", error);
    }
  };

  useEffect(() => {
    fetchApiResult();
  }, []);
  return (
    <div>
      {apiResult === 0 && (
        <div className="row mt-3 ">
          <div className="col-md-4 "></div>
          <div className="col-md-4 d-flex justify-content-end">
            {/* <button className='btn bg-transparent' style={{ border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}>Counter request</button> */}
            <button
              onClick={() => cancelRequest()}
              className="btn bg-transparent"
              style={{
                border: "#120c34 3px solid",
                borderRadius: "25px",
                fontSize: "x-large",
              }}
              disabled={loading}
            >
              cancel request
            </button>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <button
              className="btn bg-transparent"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                border: "#120c34 3px solid",
                borderRadius: "25px",
                fontSize: "x-large",
              }}
              onClick={() => handleOpen(0)}
            >
              <i class="fa-solid fa-pen"></i> edit request
            </button>
          </div>
        </div>
      )}
      {apiResult === 1 && (
        <div className="row mt-3 ">
          <div className="col-md-4 d-flex justify-content-center">
            {/* <button className='btn bg-transparent' style={{ border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}>Counter request</button> */}
            <button
              onClick={() => cancelRequest()}
              className="btn bg-transparent"
              style={{
                border: "#120c34 3px solid",
                borderRadius: "25px",
                fontSize: "x-large",
              }}
              disabled={loading}
            >
              cancel request
            </button>
          </div>
          <div className="col-md-4 d-flex justify-content-center">
            {/* <button className='btn bg-transparent' style={{ border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}>Counter request</button> */}
            <button
              className="btn bg-transparent"
              style={{
                border: "#120c34 3px solid",
                borderRadius: "25px",
                fontSize: "x-large",
              }}
              onClick={() => handleOpen(1)}
            >
              counter request
            </button>
          </div>

          <div className="col-md-4 d-flex justify-content-center">
            {/* <button className='btn bg-transparent' style={{backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)',color:"white", borderRadius: "25px", fontSize: "xx-large" }}>Accept request</button> */}
            {/* <AcceptCampaignButton campaign={campaign} /> */}
            <button
              className="btn bg-transparent"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                border: "#120c34 3px solid",
                borderRadius: "25px",
                fontSize: "x-large",
              }}
              onClick={() => acceptRequest()}
            >
              accept request
            </button>
          </div>
        </div>
      )}
      {apiResult === 3 && (
        <div className="row mt-3">
          <div className="col-md-9">
            <div
              className="d-flex align-items-center"
              style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
            >
              <h4 className="my-2 px-2">accepted:</h4>
              <input
                type="text"
                style={{ fontSize: "x-large", outline: "none" }}
                className="input-box rounded-pill p-1 border-0"
                placeholder="your request has been accepted"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <button
                className="btn bg-transparent"
                style={{
                  border: "#120c34 3px solid",
                  borderRadius: "25px",
                  fontSize: "x-large",
                }}
              >
                cancel request
              </button>
            </div>
          </div>
        </div>
      )}
      {apiResult === 5 && (
        <div className="row mt-3">
          <div className="col-md-9">
            <div
              className="d-flex align-items-center"
              style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
            >
              <h4 className="my-2 px-2">Rejected:</h4>
              <input
                type="text"
                style={{ fontSize: "x-large", outline: "none" }}
                className="input-box rounded-pill p-1 border-0"
                placeholder="your request has been rejected"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <button
                className="btn bg-transparent"
                style={{
                  border: "#120c34 3px solid",
                  borderRadius: "25px",
                  fontSize: "x-large",
                }}
              >
                cancel request
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function CampaignDetails({ campaign, handleOpen }) {
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleCardClick = (id) => {
    // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
    setSelectedCardId(selectedCardId === id ? null : id);
  };
  const platformImages = {
    instagram: "/images/instagram.png",
    youtube: "/images/youtube.png",
    tiktok: "/images/tiktok.png",
  };

  if (!campaign) {
    return (
      <div className="container-fluid text-center mt-5" style={{}}>
        <i class="fa-solid fa-code-pull-request fa-3x"></i>
        <h1>select a campaign to see details</h1>
      </div>
    );
  }

  // Assuming 'platform' is directly under 'details' and is always a string.
  const platform = campaign.details?.platform; // Access the platform information more safely
  const getImageForPlatform = (platform) => {
    return platformImages[platform.toLowerCase()] || "/images/placeholder.png";
  };

  const imageSrc = platform
    ? getImageForPlatform(platform)
    : "/images/placeholder.png";

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            proposed content
          </h5>
          <textarea
            className="p-2"
            cols="30"
            rows="9"
            style={{
              borderRadius: "25px",
              border: "#120c34 3px solid",
              width: "100%",
              fontSize: "24px",
            }}
            value={campaign.details.description || ""}
            readOnly
          />
        </div>
        <div className="col-md-8">
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            deliverables
          </h5>
          <div
            className="card"
            style={{ border: "#120c34 3px solid", borderRadius: "25px" }}
          >
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={imageSrc}
                    alt={`platform: ${platform || "Unknown"}`}
                    style={{ width: "200px", height: "200px" }}
                  />
                </div>
                <div className="col-md-8 mt-3">
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.video_story || "None"
                  } : three-frame Video stories`}</h3>
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_story || "None"
                  } : three-frame Photo stories`}</h3>
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_post || "None"
                  } : in-feed photo post `}</h3>
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.reel || "None"
                  } : 15-20 sec reel`}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3 style={{ color: "#120c34" }}>{`Frequency ${
                    campaign.details?.post_frequency || "None"
                  } : per week`}</h3>
                </div>
              </div>
            </div>
          </div>
          <h5 style={{ fontWeight: 700 }} className="text-center mt-4">
            offer: cash + product
          </h5>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <input
              type="text"
              style={{
                border: "#120c34 3px solid ",
                borderRadius: "25px",
                fontSize: "x-large",
                width: "80%",
              }}
              placeholder={`$${campaign.details?.product_value || "None"}`}
              className="datepicker input-box rounded-pill p-1 text-center"
              readOnly
            />
            <input
              type="text"
              style={{
                borderRadius: "25px",
                fontSize: "x-large",
                width: "80%",
                border: "#120c34 3px solid",
              }}
              placeholder={`${campaign.details?.product_url || "None"}`}
              className=" datepicker input-box rounded-pill p-1 text-center"
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-9">
          <div
            className="d-flex align-items-center"
            style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
          >
            <h4 className="my-2 px-2" style={{ fontWeight: 700 }}>
              attachment:
            </h4>
            <input
              type="text"
              style={{ fontSize: "xx-large", outline: "none" }}
              className="input-box rounded-pill p-1 border-0"
              placeholder={campaign.details.attachment || "attachment.jpeg"}
              readOnly
            />
          </div>
        </div>
      </div>

      <CampaignDetails_Check_Approval
        campaign={campaign}
        handleOpen={handleOpen}
      />
    </div>
  );
}

function CampaignDetails_Progress({ campaign, removeView = false }) {
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCardClick = (id) => {
    // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
    setSelectedCardId(selectedCardId === id ? null : id);
  };
  const platformImages = {
    instagram: "/images/instagram.png",
    youtube: "/images/youtube.png",
    tiktok: "/images/tiktok.png",
  };

  if (!campaign) {
    return (
      <div className="container-fluid text-center mt-5" style={{}}>
        <i class="fa-solid fa-code-pull-request fa-3x"></i>
        <h1>select a campaign to see details</h1>
      </div>
    );
  }

  // Assuming 'platform' is directly under 'details' and is always a string.
  const platform = campaign.details?.platform; // Access the platform information more safely
  const getImageForPlatform = (platform) => {
    return platformImages[platform.toLowerCase()] || "/images/placeholder.png";
  };

  const imageSrc = platform
    ? getImageForPlatform(platform)
    : "/images/placeholder.png";

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            proposed content
          </h5>
          <textarea
            className="p-2"
            cols="30"
            rows="9"
            style={{
              borderRadius: "25px",
              border: "#120c34 3px solid",
              width: "100%",
              fontSize: "24px",
            }}
            value={campaign.details.description || ""}
            readOnly
          />
        </div>
        <div className="col-md-8">
          <h5 style={{ fontWeight: 700 }} className="text-center mt-1">
            deliverables
          </h5>
          <div
            className="card"
            style={{ border: "#120c34 3px solid", borderRadius: "25px" }}
          >
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={imageSrc}
                    alt={`platform: ${platform || "Unknown"}`}
                    style={{ width: "200px", height: "200px" }}
                  />
                </div>
                <div className="col-md-8 mt-3">
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.video_story || "None"
                  } : three-frame Video stories`}</h3>
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_story || "None"
                  } : three-frame Photo stories`}</h3>
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.photo_post || "None"
                  } : in-feed photo post `}</h3>
                  <h3 style={{ color: "#120c34" }}>{`${
                    campaign.details?.reel || "None"
                  } : 15-20 sec reel`}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3 style={{ color: "#120c34" }}>{`Frequency ${
                    campaign.details?.post_frequency || "None"
                  } : per week`}</h3>
                </div>
              </div>
            </div>
          </div>
          <h5 style={{ fontWeight: 700 }} className="text-center mt-4">
            offer: cash + product
          </h5>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <input
              type="text"
              style={{
                border: "#120c34 3px solid ",
                borderRadius: "25px",
                fontSize: "x-large",
                width: "80%",
              }}
              placeholder={`$${campaign.details?.product_value || "None"}`}
              className="datepicker input-box rounded-pill p-1 text-center"
              readOnly
            />
            <input
              type="text"
              style={{
                borderRadius: "25px",
                fontSize: "x-large",
                width: "80%",
                border: "#120c34 3px solid",
              }}
              placeholder={`${campaign.details?.product_url || "None"}`}
              className=" datepicker input-box rounded-pill p-1 text-center"
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-9">
          <div
            className="d-flex align-items-center"
            style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
          >
            <h4 className="my-2 px-2" style={{ fontWeight: 700 }}>
              URL:
            </h4>
            <input
              type="text"
              style={{ fontSize: "x-large", outline: "none", width: "80%" }}
              className="input-box rounded-pill p-1 border-0"
              placeholder={campaign.details.url}
              readOnly
            />
          </div>
        </div>
        <div className="col-md-3">
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            {/* <input type="text" style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 3px solid" }} placeholder="Edit request" className=" datepicker input-box rounded-pill p-1 text-center" readOnly /> */}
            {/* <button className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}><i class="fa-solid fa-pen"></i> edit request</button> */}
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-9">
          <div
            className="d-flex align-items-center"
            style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}
          >
            <h4 style={{ fontWeight: 700 }} className="my-2 px-2">
              attachment:
            </h4>
            <input
              type="text"
              style={{ fontSize: "x-large", outline: "none" }}
              className="input-box rounded-pill p-1 border-0"
              placeholder={campaign.details.attachment || "attachment.jpeg"}
              readOnly
            />
          </div>
        </div>
        <div className="col-md-3"></div>
        {!removeView && (
          <div className="col-md-12 mb-2">
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <CustomButton
                onClick={() => handleShow()}
                style={{ fontSize: "24px" }}
                label="view content"
              />
            </div>
          </div>
        )}
      </div>

      <ModalComponent
        show={showModal}
        onHide={handleClose}
        bodyClass="edit-body-class"
        bodyContent={<ViewContent data={campaign} handleClose={handleClose} />}
      />
    </div>
  );
}

const ViewContent = ({ data, handleClose }) => {
  console.log(data, "datadatadatadata");
  const [page, setPage] = useState(1);
  const [content, setContent] = useState([]);
  const [next, setNext] = useState(null);
  const [loading, setLoading] = useState(false);

  const cancelRequest = async (id) => {
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/reject/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        toast.success(data?.message);
        fetchContent(true);
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const acceptRequest = async (id) => {
    const token = localStorage.getItem("accessToken"); // Retrieve the access token from local storage
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/approve/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "SUCCESS") {
        toast.success(data?.message);
        fetchContent(true);
      } else {
        console.error("Failed to fetch campaigns:", data.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchContent = async (reset = false) => {
    setLoading(true);
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }api/campaign/v1/content?campaign=${91}&type=marketplace&page=${
        reset ? 1 : page
      }&page_size=10`,
      {
        headers: {
          Authorization: `Bearer ${isLogedin}`,
        },
      }
    );

    const result = await response.json();
    if (result.status === "SUCCESS") {
      if (reset) {
        setContent(result?.data?.results);
      } else {
        const lastCurrentItem = content?.[content.length - 1];
        const lastNewItem = result.data.results[result.data.results.length - 1];
        const isSame = lastCurrentItem?.id === lastNewItem?.id;
        if (!isSame) {
          setContent((prev) => [...prev, ...result.data.results]);
        }
        setContent(result?.data?.results);
      }
    }
    setLoading(false);
    setNext(result.data.next);
  };

  useEffect(() => {
    if (data) {
      fetchContent();
    }
  }, []);

  return (
    <div style={{ margin: "-16px", borderRadius: "25px", overflow: "hidden" }}>
      <table
        className="table mb-0"
        style={{
          background: "#120c34",
          color: "white",
          maxHeight: "500px",
          overflowY: "scroll",
        }}
      >
        <colgroup>
          <col className="image-col" />
          <col className="status-col" />
          <col className="input-col" />
        </colgroup>
        <thead className="text-center">
          <tr>
            <th scope="col">Content</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {content?.length
            ? content?.map((item) => (
                <tr key={item.id}>
                  <td data-label="Image">
                    <a
                      href={item.image}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        width="74px"
                        style={{ height: "50px" }}
                        src={item.image}
                        alt="House"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td
                    style={{ justifyContent: "center", display: "flex" }}
                    data-label="Status"
                  >
                    {item.status === "Rejected" ? (
                      <span className="badge badge-danger p-1">{item.status}</span>
                    ) : item.status === "Approved" ? (
                      <span className="badge badge-success p-1">{item.status}</span>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div className="input-group-append">
                          <div
                            style={{
                              cursor: "pointer",
                              borderRadius: "100%",
                              padding: "8px",
                              background: "red",
                            }}
                            onClick={() => cancelRequest(item.id)}
                          >
                            <i
                              class="fa-solid fa-xmark "
                              style={{ color: "white" }}
                            ></i>
                          </div>
                        </div>
                        <div className="input-group-append">
                          <div
                            style={{
                              cursor: "pointer",
                              borderRadius: "100%",
                              padding: "8px",
                              background: "green",
                            }}
                            onClick={() => acceptRequest(item.id)}
                          >
                            <i
                              class="fa-solid fa-check "
                              style={{ color: "white" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            : !loading && (
                <tr>
                  <th colSpan={3}>no data found</th>
                </tr>
              )}
        </tbody>
      </table>
    </div>
  );
};

export default function Manage_req() {
  const [activeTab, setActiveTab] = useState("pending"); // Default active tab
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedCampaignP, setSelectedCampaignP] = useState(null);
  const [selectedCampaignC, setSelectedCampaignC] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowEditModal(false);
  };

  const handleOpen = (counter) => {
    setShowEditModal(true);
    setCounter(counter);
  };

  useEffect(() => {
    const loggedInStatus = window.localStorage.getItem("isLogedin") === "true";
    // console.log("t1=", loggedInStatus);
    setIsLoggedIn(loggedInStatus);

    if (!loggedInStatus) {
      navigate("/signin", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    // console.log("t2=", isLoggedIn);
  }, [isLoggedIn]);

  const handleCardClick = (id) => {
    // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
    setSelectedCardId(selectedCardId === id ? null : id);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedCampaign(null);
    setSelectedCampaignP(null);
  };
  const backButtonStyle = {
    background: "none",
    border: "none",
    color: "inherit",
    padding: 0,
    fontSize: "inherit",
    cursor: "pointer",
    marginBottom: "5px",
    fontSize: "25px",
  };
  const getBackgroundColor = (tabName) => {
    return activeTab === tabName ? "#120c34" : "#fff"; // Change #fff to your default or inactive color
  };
  const getTextColor = (tabName) => {
    return activeTab === tabName ? "#fff" : "#120c34"; // Active text color #fff, inactive text color #120c34
  };

  if (!isLoggedIn) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row ">
          <button
            className="ml-3 d-flex align-items-center"
            onClick={() => window.history.back()}
            style={backButtonStyle}
          >
            <i
              className="fa fa-arrow-left mr-1"
              style={{ fontSize: "13px" }}
            ></i>
            back
          </button>
        </div>
        <div className="row">
          <h3 style={{ fontSize: "40px", marginLeft: "100px" }}>requests</h3>
        </div>
        <div className="row">
          <div className="col-4 col-sm-4">
            <div className="card border-0">
              <div
                onClick={() => handleTabClick("pending")}
                className="card-body "
                style={{
                  border: "#120c34 3px solid",
                  background: getBackgroundColor("pending"),
                  borderTopLeftRadius: "25px",
                  cursor: "pointer",
                  borderTopRightRadius: "25px",
                }}
              >
                <h3
                  className=" text-center"
                  style={{
                    cursor: "pointer",
                    color: getTextColor("pending"),
                    fontWeight: 700,
                  }}
                >
                  pending
                </h3>
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-4">
            <div className="card border-0">
              <div
                className="card-body "
                onClick={() => handleTabClick("in progress")}
                style={{
                  border: "#120c34 3px solid",
                  background: getBackgroundColor("in progress"),
                  cursor: "pointer",
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                }}
              >
                <h3
                  className=" text-center"
                  style={{
                    cursor: "pointer",
                    color: getTextColor("in progress"),
                    fontWeight: 700,
                  }}
                >
                  in progress
                </h3>
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-4">
            <div className="card border-0">
              <div
                className="card-body "
                onClick={() => handleTabClick("completed")}
                style={{
                  border: "#120c34 3px solid",
                  background: getBackgroundColor("completed"),
                  cursor: "pointer",
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                }}
              >
                <h3
                  className=" text-center"
                  style={{
                    cursor: "pointer",
                    color: getTextColor("completed"),
                    fontWeight: 700,
                  }}
                >
                  completed
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row container-fluid m-0"
          style={{ background: "#120c34" }}
        >
          {activeTab === "pending" && (
            <div
              style={{ borderRadius: "25px", overflow: "hidden !important" }}
              className="container-fluid mt-4 bg-white"
            >
              <div className="row">
                <div
                  className="col-md-4 px-0 hide-scrollbar"
                  style={{
                    overflowY: "scroll",
                    height: "70vh",
                    borderTopLeftRadius: "25px",
                  }}
                >
                  <CampaignCards onCardSelect={setSelectedCampaign} />
                </div>
                <div className="col-md-8 px-0">
                  <CampaignDetails
                    handleOpen={handleOpen}
                    campaign={selectedCampaign}
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab === "in progress" && (
            <div
              style={{ borderRadius: "25px", overflow: "hidden !important" }}
              className="container-fluid mt-4 bg-white"
            >
              <div className="row">
                <div
                  className="col-md-4 px-0 hide-scrollbar"
                  style={{
                    overflowY: "scroll",
                    height: "70vh",
                    borderTopLeftRadius: "25px",
                  }}
                >
                  <CampaignCardsInProgress
                    onCardSelect={setSelectedCampaignP}
                  />
                </div>
                <div className="col-md-8 px-0">
                  <CampaignDetails_Progress campaign={selectedCampaignP} />
                </div>
              </div>
            </div>
          )}
          {activeTab === "completed" && (
            <div
              style={{ borderRadius: "25px", overflow: "hidden !important" }}
              className="container-fluid mt-4 bg-white"
            >
              <div className="row">
                <div
                  className="col-md-4 px-0 hide-scrollbar"
                  style={{
                    overflowY: "scroll",
                    height: "70vh",
                  }}
                >
                  <CampaignCardsCompleted onCardSelect={setSelectedCampaignC} />
                </div>
                <div className="col-md-8 px-0">
                  <CampaignDetails_Progress
                    removeView={true}
                    campaign={selectedCampaignC}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
      <ModalComponent
        show={showEditModal}
        onHide={handleClose}
        bodyClass="edit-body-class"
        bodyContent={
          <EditMarketplaceRequest
            data={selectedCampaign}
            handleClose={handleClose}
            counter={counter}
          />
        }
      />
    </div>
  );
}
