import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const PasswordChangeForm = ({ handleClose }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const changePassword = async (oldPassword, newPassword) => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      window.location.href = "/signin";
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/auth/v1/password/change`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            old_password: oldPassword,
            new_password: newPassword,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result?.message, "result?.message");
        if (result.status === "SUCCESS") {
          toast.success(result?.message);
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          handleClose();
        } else {
          toast.error(result?.message);
        }
      } else {
        const result = await response.json();
        toast.error(result?.message);
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset previous errors
    setErrors({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    // Validation checks
    let formValid = true;
    const newErrors = {};

    if (!oldPassword) {
      newErrors.oldPassword = "old password is required.";
      formValid = false;
    }

    if (!newPassword) {
      newErrors.newPassword = "new password is required.";
      formValid = false;
    } else if (newPassword.length < 8) {
      newErrors.newPassword = "new password must be at least 8 characters.";
      formValid = false;
    } else if (!/[A-Z]/.test(newPassword)) {
      newErrors.newPassword =
        "new password must contain at least one uppercase letter.";
      formValid = false;
    } else if (!/[0-9]/.test(newPassword)) {
      newErrors.newPassword = "new password must contain at least one number.";
      formValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      newErrors.newPassword =
        "new password must contain at least one special character.";
      formValid = false;
    }

    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword =
        "new password and confirm password do not match.";
      formValid = false;
    }

    setErrors(newErrors);

    // If form is valid, handle the password change
    if (formValid) {
      // Simulate successful password change
      changePassword(oldPassword, newPassword);
    }
  };

  return (
    <div style={{ width: "500px" }} className="container my-2">
      <Form
        className="d-flex flex-column"
        style={{ gap: "8px" }}
        onSubmit={handleSubmit}
      >
        {/* old Password */}
        <Form.Group controlId="formOldPassword">
          <Form.Label>old password</Form.Label>
          <Form.Control
            type="password"
            placeholder="old password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          {errors.oldPassword && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {errors.oldPassword}
            </div>
          )}
        </Form.Group>

        {/* new Password */}
        <Form.Group controlId="formNewPassword">
          <Form.Label>new password</Form.Label>
          <Form.Control
            type="password"
            placeholder="new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {errors.newPassword && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {errors.newPassword}
            </div>
          )}
        </Form.Group>

        {/* confirm Password */}
        <Form.Group controlId="formConfirmPassword">
          <Form.Label>confirm password</Form.Label>
          <Form.Control
            type="password"
            placeholder="confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors.confirmPassword && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {errors.confirmPassword}
            </div>
          )}
        </Form.Group>

        <Button
          className="mt-2"
          style={{
            background: "#120c34",
            border: "none",
            borderRadius: "25px",
          }}
          type="submit"
        >
          submit
        </Button>
      </Form>
    </div>
  );
};

export default PasswordChangeForm;
