import React from "react";

const EmbassyViewModal = ({
  item = [],
  handleApprove,
  handleReject,
  handleImageError,
  handleClose,
}) => {
  return (
    <div
      className="p-0"
      style={{ background: "#120c34", borderRadius: "25px", margin: "-25px" }}
    >
      <div style={{ borderRadius: "25px" }}>
        <div style={{ position: "relative" }}>
          <div
            className="text-center"
            style={{
              position: "relative",
              overflow: "hidden",
            }}
          >
            <button
              type="button"
              className="close"
              onClick={() => handleClose()}
              aria-label="Close"
              style={{
                zIndex: "10000000000",
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "1.5rem",
                color: "#fff",
              }}
            >
              <i class="fa-solid fa-xmark fa-2x" style={{ color: "white" }}></i>
            </button>
            <img
              className="original-image"
              src={item.image}
              alt="User"
              style={{
                height: "65vh",
                position: "relative",
                zIndex: 2,
              }}
              onError={(event) => handleImageError(event, item.image)}
            />
          </div>
          <div class="blur-effect"></div>
          <div class="shade-effect "></div>
        </div>
      </div>
      <a
        style={{
          fontSize: "28px",
          textDecoration: "none",
        }}
        href={item?.creator_link}
        className="text-white px-3 py-1 decoration-none"
      >
        @{item.creator}
      </a>
      <p className="text-white px-3">{item.caption}</p>
      {(!(item.status === "Approved") || !(item.status === "Rejected")) && (
        <div
          className="p-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20%",
          }}
        >
          <div
            className="card bg-success"
            style={{ borderRadius: "100%" }}
            onClick={() => handleReject(item.id)}
          >
            <div
              className="card-body bg-danger text-center "
              style={{
                borderRadius: "100%",
                border: "white solid 3px",
              }}
            >
              <i
                class="fa-solid fa-xmark fa-3x"
                style={{ color: "#120c34" }}
              ></i>
            </div>
          </div>
          <div
            className="card bg-success"
            style={{ borderRadius: "100%" }}
            onClick={() => handleApprove(item.id)}
          >
            <div
              className="card-body bg-sucess text-center "
              style={{
                borderRadius: "100%",
                border: "white solid 3px",
              }}
            >
              <i
                class="fa-solid fa-check fa-3x"
                style={{ color: "#120c34" }}
              ></i>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmbassyViewModal;
