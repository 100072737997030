import React from "react";

const InterestsModal = ({ data, value, handleChange }) => {
  return (
    <div>
      {" "}
      <div className="d-flex flex-wrap align-items-center">
        {data?.map((item) => (
          <label
            key={item.id}
            style={{
              display: "block",
              cursor: "pointer",
              borderRadius: "25px",
              padding: "10px 20px",
              fontWeight: "800",
              fontSize: "0.8em",
              backgroundColor: value.includes(item.id?.toString())
                ? "#120c34"
                : "white",
              color: value.includes(item.id?.toString()) ? "white" : "#120c34",
              border: !value.includes(item.id?.toString())
                ? "1px solid #120c34"
                : "none",
              margin: "5px 5px 0 0",
              transition: "background-color 0.3s ease",
            }}
          >
            <input
              type="checkbox"
              value={item.id}
              checked={value.includes(item.id?.toString())}
              onChange={(e) => handleChange(e)}
              style={{ display: "none" }} // Hide the default checkbox input
            />
            {item.name}
          </label>
        ))}
      </div>
    </div>
  );
};

export default InterestsModal;
