import React from "react";

const LimitedOffer = ({ handleBack }) => {
  return (
    <div>
      {" "}
      <>
        <div className="row">
          <div className="col-2">
            <button
              className="bg-transparent border-0 cursor-pointer d-flex align-items-center"
              onClick={() => handleBack()}
            >
              <i
                className="fa fa-arrow-left mr-1"
                style={{ fontSize: "13px" }}
              ></i>
              back
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <h5
            className="text-center set_font_mainPage mt-3"
            style={{ color: "#120c34" }}
          >
            Limited offer
          </h5>
          <h4 className="text-center " style={{ color: "#120c34" }}>
            Subscribe today and receive 30 days free and $100 towards your brand
            embassay ambassador progam.
          </h4>
          <h5
            className="text-center set_font_mainPage mt-3"
            style={{ color: "#120c34" }}
          >
            Subscription
          </h5>
          <div className="table-responsive p-0 mx-0">
            <table
              className="table"
              style={{
                borderTop: "2px solid #120c34",
                borderBottom: "2px solid #120c34",
              }}
            >
              <tbody>
                <tr style={{ borderTop: "2px solid #120c34" }}>
                  <th>
                    <h4 className="text-center">Tier</h4>
                  </th>
                  <th>
                    <h4 className="text-center">Feature</h4>
                  </th>
                  <th>
                    <h4 className="text-center">Price</h4>
                  </th>
                </tr>

                <tr
                  style={{
                    borderTop: "2px solid #120c34",
                    borderBottom: "2px solid #120c34",
                    borderCollapse: "separate",
                    borderSpacing: "0",
                  }}
                >
                  <td>
                    <h5>micro</h5>
                  </td>
                  <td className="text-center">
                    <h5>
                      brand embassy : 15% transaction fee <br /> campaign : 15%
                      transaction fee <br />
                      marketplace : unlock creator with less then 100k followers
                    </h5>
                  </td>
                  <td>
                    <button
                      style={{
                        borderRadius: "25px",
                        border: "2px solid #120c33",
                        padding: "5px 10px",
                        backgroundColor: "transparent",
                        color: "#120c33",
                        cursor: "pointer",
                      }}
                      className="btn btn-lg "
                    >
                      $99/month
                    </button>
                  </td>
                </tr>
                <tr
                  style={{
                    borderTop: "2px solid #120c34",
                    borderBottom: "2px solid #120c34",
                  }}
                >
                  <td>
                    <h5>Macro</h5>
                  </td>
                  <td className="text-center">
                    <h5>
                      brand embassy : 10% transaction fee <br /> campaign : 10%
                      transaction fee <br />
                      marketplace : unlock creator with less then 10M followers
                    </h5>
                  </td>
                  <td>
                    <button
                      style={{
                        borderRadius: "25px",
                        border: "2px solid #120c33",
                        padding: "5px 10px",
                        backgroundColor: "transparent",
                        color: "#120c33",
                        cursor: "pointer",
                      }}
                      className="btn btn-lg "
                    >
                      $249/month
                    </button>
                  </td>
                </tr>
                <tr
                  style={{
                    borderTop: "2px solid #120c34",
                    borderBottom: "2px solid #120c34",
                  }}
                >
                  <td>
                    <h5>Mega</h5>
                  </td>
                  <td className="text-center">
                    <h5>
                      brand embassy : 5% transaction fee <br /> campaign : 5%
                      transaction fee <br />
                      marketplace : unlock all creators
                    </h5>
                  </td>
                  <td>
                    <button
                      style={{
                        borderRadius: "25px",
                        border: "2px solid #120c33",
                        padding: "5px 10px",
                        backgroundColor: "transparent",
                        color: "#120c33",
                        cursor: "pointer",
                      }}
                      className="btn btn-lg "
                    >
                      $999/month
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-center mt-3">
            <button
              style={{
                borderRadius: "25px",
                border: "2px solid #120c34",
                padding: "10px 20px",
                backgroundColor: "transparent",
                color: "#120c34",
                cursor: "pointer",
                fontSize: "x-large",
                fontWeight: "bold",
              }}
              className="btn"
            >
              Decline offer
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default LimitedOffer;
