import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";

export default function Campaign_Manage() {
  const [campaigns, setCampaigns] = useState([]);
  const [editState, setEditState] = useState(false);
  const backButtonStyle = {
    background: "none",
    border: "none",
    color: "inherit",
    padding: 0,
    fontSize: "inherit",
    cursor: "pointer",
    marginBottom: "5px",
    fontSize: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const location = useLocation();
  const campaignId = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/${campaignId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.status === "SUCCESS") {
        setCampaigns(result.data);
      } else {
        console.error("Failed to fetch campaigns");
      }
    };

    fetchData();
  }, []);
  const campaignTypeLabels = {
    1: "UGC",
    2: "Posted Content",
    3: "Growth",
    4: "Content",
  };

  const [formData, setFormData] = useState({
    platform: "",
    post_frequency: "",
    description: "",
    attachments: "",
    url: "",
    gender: "",
    maxAge: "",
    minAge: "",
    endDate: "",
    location_d: "",
    interests: "",
    startDate: "",
    costPerTarget: "",
    contentFormate: "",
    maximumAudience: "",
    minimumDileverable: "",
    estimatedParticipants: "",
    participantAudienceSizeMAX: "",
    participantAudienceSizeMIN: "",
  });

  function DisplayAttachments({ campaigns }) {
    // Check if campaigns and campaigns.details exist, otherwise set attachments to an empty array
    const attachments =
      campaigns && campaigns.details && campaigns.details.attachments
        ? campaigns.details.attachments
        : [];

    // Render different UI based on the number of attachments
    if (attachments.length === 1) {
      return (
        <a
          className=""
          href={attachments[0]}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>
      );
    } else if (attachments.length > 1) {
      return (
        <div className="dropdown">
          <button
            className="bg-transparent border-0 dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {attachments.length} attachment{attachments.length > 1 ? "s" : ""}{" "}
            uploaded
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {attachments.map((attachment, index) => (
              <a
                key={index}
                className="dropdown-item"
                href={attachment}
                target="_blank"
                rel="noopener noreferrer"
              >
                attachment {index + 1}
              </a>
            ))}
          </div>
        </div>
      );
    } else {
      return <span>no attachments available</span>;
    }
  }

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();

  // Handle input change for any field
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the specific field in formData based on the name
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state
      [name]: value, // Dynamically set the updated field
    }));
  };

  useEffect(() => {
    const loggedInStatus = window.localStorage.getItem("isLogedin") === "true";
    // console.log("t1=", loggedInStatus);
    setIsLoggedIn(loggedInStatus);

    if (!loggedInStatus) {
      navigate("/signin", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    if (campaigns && campaigns.details) {
      const campaignData = {
        platform: campaigns.details.platform ?? "",
        post_frequency: campaigns.details.post_frequency ?? "",
        description: campaigns.details.description ?? "",
        attachments: campaigns.details.attachments ?? "",
        url: campaigns.details.url ?? "",
        gender: campaigns.details.gender ?? "",
        maxAge: campaigns.details.maxAge ?? "",
        minAge: campaigns.details.minAge ?? "",
        endDate: campaigns.details.endDate ?? "",
        location_d: campaigns.details.location ?? "",
        interests: campaigns.details.interests ?? "",
        startDate: campaigns.details.startDate ?? "",
        costPerTarget: campaigns.details.costPerTarget ?? "",
        contentFormate: campaigns.details.contentFormate ?? "",
        maximumAudience: campaigns.details.maximumAudience ?? "",
        minimumDileverable: campaigns.details.minimumDileverable ?? "",
        estimatedParticipants: campaigns.details.estimatedParticipants ?? "",
        participantAudienceSizeMAX:
          campaigns.details.participantAudienceSizeMAX ?? "",
        participantAudienceSizeMIN:
          campaigns.details.participantAudienceSizeMIN ?? "",
        campaign_type: campaigns.campaign_type ?? "",
      };
      setFormData(campaignData);
    }
  }, [campaigns]);

  useEffect(() => {
    // console.log("t2=", isLoggedIn);
  }, [isLoggedIn]);
  // // console.log(campaignTypeLabels[campaigns.campaign_type])
  if (campaigns.campaign_type === 3) {
    // console.log("campaigns.campaign_type: ", campaigns.campaign_type)
    return (
      <div>
        <div className="container-fluid">
          <div className="row mt-2 d-flex align-items-center">
            <div className="col-md-1">
              <button
                onClick={() => window.history.back()}
                style={backButtonStyle}
                className="d-flex align-items-center"
              >
                <i
                  className="fa fa-arrow-left mr-1"
                  style={{ fontSize: "13px" }}
                ></i>
                back
              </button>
            </div>
            <div className="offset-2 col-md-6 text-center">
              {editState ? (
                <input
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    border: "#120c34 2px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    background: "#120c34",
                  }}
                  disabled={!editState}
                  placeholder={`${campaigns.title}: ${formData?.startDate} - ${formData?.endDate}`}
                  className="input-box1 rounded-pill p-2 text-center text-light"
                />
              ) : (
                <div
                  style={{
                    border: "#120c34 2px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    background: "#120c34",
                  }}
                  className="input-box1 rounded-pill p-2 text-center text-light"
                >{`${campaigns.title}: ${formData?.startDate} - ${formData?.endDate}`}</div>
              )}
            </div>
            <div
              className="col-md-3 "
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* <input type="text" onChange={e => handleInputChange('campaignTitle', e.target.value)} style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid", cursor: "pointer" }} placeholder="Edit Campaign" className="input-box rounded-pill p-1 text-center" readOnly /> */}
              <button
                onClick={() => {
                  setEditState(!editState);
                  navigate(`/campaigns_edit?id=${campaignId}`);
                }}
                className="btn bg-transparent"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  border: "#120c34 2px solid",
                  borderRadius: "25px",
                  fontSize: "x-large",
                }}
              >
                <i class="fa-solid fa-pen"></i>{" "}
                {!editState ? "edit request" : "save"}
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4 text-center">
              <h4 style={{ color: "#120c34" }}>budget remaining</h4>
              <div
                className="card text-white "
                style={{ borderRadius: "25px", border: "#120c34 2px solid" }}
              >
                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                  <div className="text-left ">
                    <h4 className="px-3 pt-2" style={{ color: "#120c34" }}>
                      ${campaigns.budget}
                    </h4>
                  </div>
                  <button
                    className="btn btn-lg"
                    style={{
                      width: "50%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "0.8em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        border: "0",
                        fontSize: "1.1rem",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      Add Funds
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <h4 style={{ color: "#120c34" }}>deliverables</h4>
              <div
                className="card text-white "
                style={{ borderRadius: "25px", border: "#120c34 2px solid" }}
              >
                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                  <div className="text-left ">
                    <h4 className="px-3 pt-2" style={{ color: "#120c34" }}>
                      $ {formData?.minimumDileverable}
                    </h4>
                  </div>
                  <button
                    className="btn btn-lg"
                    style={{
                      width: "50%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "0.8em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        border: "0",
                        fontSize: "1.1rem",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      View
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <h4 style={{ color: "#120c34" }}>engaged target</h4>
              <div
                className="card text-white "
                style={{ borderRadius: "25px", border: "#120c34 2px solid" }}
              >
                <div className="card-body  border-0 p-1 ">
                  <div className="">
                    <h4
                      className="px-3 pt-2"
                      style={{
                        color: "#120c34",
                        borderRadius: "0",
                        padding: "9px 15px",
                      }}
                    >
                      ${formData?.post_frequency}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              {editState ? (
                <input
                  type="text"
                  style={{
                    border: "#120c34 2px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    background: "#120c34",
                  }}
                  value={formData?.campaign_type}
                  onChange={(e) => handleInputChange(e)}
                  name="campaign_type"
                  disabled={!editState}
                  placeholder={"Enter campaign goal"}
                  className="input-box1 rounded-pill p-2 text-center text-light"
                />
              ) : (
                <div
                  style={{
                    border: "#120c34 2px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    background: "#120c34",
                  }}
                  className="input-box1 rounded-pill p-2 text-center text-light"
                >{`campaign goal: ${
                  campaignTypeLabels[campaigns.campaign_type] || "Unknown"
                }`}</div>
              )}
            </div>
            <div className="col-md-4">
              {editState ? (
                <input
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    border: "#120c34 2px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    background: "#120c34",
                  }}
                  name="platform"
                  placeholder={`enter platform`}
                  className="input-box1 rounded-pill p-2 text-center text-light"
                  readOnly
                />
              ) : (
                <div
                  style={{
                    border: "#120c34 2px solid ",
                    borderRadius: "25px",
                    fontSize: "x-large",
                    width: "100%",
                    background: "#120c34",
                  }}
                  className="input-box1 rounded-pill p-2 text-center text-light"
                >{`platform: ${formData?.platform}`}</div>
              )}
            </div>
            <div className="col-md-4">
              <input
                type="text"
                onChange={(e) => handleInputChange(e)}
                style={{
                  border: "#120c34 2px solid ",
                  borderRadius: "25px",
                  fontSize: "x-large",
                  width: "100%",
                  background: "#120c34",
                }}
                placeholder={`content format: ${formData?.contentFormate}`}
                className="input-box1 rounded-pill p-2 text-center text-light"
                readOnly
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 text-center">
              <h4 className="mt-2" style={{ color: "#120c34" }}>
                campaign description
              </h4>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                style={{
                  borderRadius: "25px",
                  fontSize: "x-large",
                  width: "100%",
                  border: "#120c34 2px solid",
                  color: "#2d3146",
                }}
                className="input-box rounded-pill px-3 py-2 "
                placeholder={formData?.description}
                readOnly
              />
              <div className="card border-0 mt-3">
                <div
                  className="card-body"
                  style={{ background: "#120c34", borderRadius: "25px" }}
                >
                  <div className="row">
                    <div className="col-md-3">
                      <h3 className="text-center text-white" style={{}}>
                        age
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={`${formData?.minAge} - ${formData?.maxAge}`}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                    <div className="col-md-3">
                      <h3 className="text-center text-white" style={{}}>
                        gender
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.gender}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <h3 className="text-center text-white" style={{}}>
                        location
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.location_d || "NA"}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className="my-2 px-2" style={{}}>
                      url:
                    </h4>
                    <input
                      type="text"
                      style={{
                        fontSize: "x-large",
                        outline: "none",
                        width: "80%",
                        color: "#120c34",
                      }}
                      className="input-box rounded-pill p-1 border-0"
                      value={formData?.url}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className="my-2 px-2" style={{}}>
                      attachment:
                    </h4>
                    {/* <input type="text" style={{ fontSize: "x-large", outline: 'none', width: "80%" }} className="input-box rounded-pill p-1 border-0" value={attachments} readOnly /> */}
                    {/* <a to={attachments}>Click here</a> */}
                    {/* <a href={attachments}>Click here</a> */}
                    <DisplayAttachments campaigns={campaigns} />
                  </div>
                </div>
              </div>
              <div className="card mt-1 border-0">
                <h4 className="mt-2 text-center" style={{ color: "#120c34" }}>
                  campaign details
                </h4>
                <div
                  className="card-body border-0 text-center"
                  style={{ background: "#120c34", borderRadius: "25px" }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="text-center text-white" style={{}}>
                        maximum audience
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.maximumAudience}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <h3 className="text-center text-white" style={{}}>
                        cost per target
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.costPerTarget}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <h3 className="text-center text-white" style={{}}>
                        participants audience size
                      </h3>
                      <div className="d-flex">
                        <input
                          type="text"
                          style={{
                            border: "#120c34 2px solid ",
                            borderRadius: "25px",
                            fontSize: "x-large",
                            width: "100%",
                            color: "#120c34",
                          }}
                          value={formData?.participantAudienceSizeMIN}
                          className="input-box rounded-pill text-center"
                          readOnly
                        />
                        <h3 className="text-center text-white" style={{}}>
                          to
                        </h3>
                        <input
                          type="text"
                          style={{
                            border: "#120c34 2px solid ",
                            borderRadius: "25px",
                            fontSize: "x-large",
                            width: "100%",
                            color: "#120c34",
                          }}
                          value={formData?.participantAudienceSizeMAX}
                          className="input-box rounded-pill text-center"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <h3 className="text-center text-white" style={{}}>
                        deliverables per participants
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "50%",
                          color: "#120c34",
                        }}
                        placeholder="1-10"
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="container-fluid">
          <div className="row mt-2 d-flex align-items-center">
            <div className="col-md-1">
              <button
                onClick={() => window.history.back()}
                style={backButtonStyle}
                className="d-flex align-items-center"
              >
                <i
                  className="fa fa-arrow-left mr-1"
                  style={{ fontSize: "13px" }}
                ></i>
                back
              </button>
            </div>
            <div className="offset-2 col-md-6 text-center">
              <input
                type="text"
                onChange={(e) => handleInputChange(e)}
                style={{
                  border: "#120c34 2px solid ",
                  borderRadius: "25px",
                  fontSize: "x-large",
                  width: "100%",
                  background: "#120c34",
                }}
                placeholder={`${campaigns.title}: ${formData?.startDate} - ${formData?.endDate}`}
                className="input-box1 rounded-pill p-2 text-center text-light"
                readOnly
              />
            </div>
            <div
              className="col-md-3 "
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* <input type="text" onChange={e => handleInputChange('campaignTitle', e.target.value)} style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid", cursor: "pointer" }} placeholder="Edit Campaign" className="input-box rounded-pill p-1 text-center" readOnly /> */}
              <button
                onClick={() => {
                  setEditState(!editState);
                  navigate(`/campaigns_edit?id=${campaignId}`);
                }}
                className="btn bg-transparent"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  border: "#120c34 2px solid",
                  borderRadius: "25px",
                  fontSize: "x-large",
                }}
              >
                <i class="fa-solid fa-pen"></i> edit request
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4 text-center">
              <h4 style={{ color: "#120c34" }}>budget remaining</h4>
              <div
                className="card text-white "
                style={{ borderRadius: "25px", border: "#120c34 2px solid" }}
              >
                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                  <div className="text-center">
                    <h4
                      className="px-3 pt-2 text-center"
                      style={{ color: "#120c34" }}
                    >
                      ${campaigns.budget}
                    </h4>
                  </div>
                  <button
                    className="btn btn-lg"
                    style={{
                      width: "50%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "0.8em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        border: "0",
                        fontSize: "1.1rem",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      Add Funds
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <h4 style={{ color: "#120c34" }}>deliverables</h4>
              <div
                className="card text-white "
                style={{ borderRadius: "25px", border: "#120c34 2px solid" }}
              >
                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                  <div className="text-left">
                    <h4 className="px-3 pt-2" style={{ color: "#120c34" }}>
                      ${formData?.minimumDileverable}
                    </h4>
                  </div>
                  <button
                    className="btn btn-lg"
                    style={{
                      width: "50%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "0.8em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        border: "0",
                        fontSize: "1.1rem",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      View
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <h4 style={{ color: "#120c34" }}>Current Participants</h4>
              <div
                className="card text-white "
                style={{ borderRadius: "25px", border: "#120c34 2px solid" }}
              >
                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                  <div className="text-left">
                    <h4 className="px-3 pt-2" style={{ color: "#120c34" }}>
                      {formData?.post_frequency || "-"}
                    </h4>
                  </div>
                  <button
                    className="btn btn-lg"
                    style={{
                      width: "50%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "0.8em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        border: "0",
                        fontSize: "1.1rem",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      View
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 justify-content-center ">
            <div className="col-md-4">
              <input
                type="text"
                onChange={(e) => handleInputChange(e)}
                style={{
                  border: "#120c34 2px solid ",
                  borderRadius: "25px",
                  fontSize: "x-large",
                  width: "100%",
                  background: "#120c34",
                }}
                placeholder={`campaign goal: ${
                  campaignTypeLabels[campaigns.campaign_type] || "Unknown"
                }`}
                className="input-box1 rounded-pill p-2 text-center text-light"
                readOnly
              />
            </div>
            <div className="col-md-4">
              <input
                type="text"
                onChange={(e) => handleInputChange(e)}
                style={{
                  border: "#120c34 2px solid ",
                  borderRadius: "25px",
                  fontSize: "x-large",
                  width: "100%",
                  background: "#120c34",
                }}
                placeholder={`content format: ${formData?.contentFormate}`}
                className="input-box1 rounded-pill p-2 text-center text-light"
                readOnly
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 text-center">
              <h4 className="mt-2" style={{ color: "#120c34" }}>
                campaign description
              </h4>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                style={{
                  borderRadius: "25px",
                  fontSize: "x-large",
                  width: "100%",
                  border: "#120c34 2px solid",
                  color: "#2d3146",
                }}
                className="input-box rounded-pill px-3 py-2 "
                placeholder={formData?.description}
                readOnly
              />
              <div className="card border-0 mt-3">
                <div
                  className="card-body"
                  style={{ background: "#120c34", borderRadius: "25px" }}
                >
                  <div className="row">
                    <div className="col-md-3">
                      <h3 className="text-center text-white" style={{}}>
                        age
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={`${formData?.minAge} - ${formData?.maxAge}`}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                    <div className="col-md-3">
                      <h3 className="text-center text-white" style={{}}>
                        gender
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.gender}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <h3 className="text-center text-white" style={{}}>
                        location
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.location_d || "NA"}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className="my-2 px-2" style={{}}>
                      url:
                    </h4>
                    <input
                      type="text"
                      style={{
                        fontSize: "x-large",
                        outline: "none",
                        width: "80%",
                        color: "#120c34",
                      }}
                      className="input-box rounded-pill p-1 border-0"
                      value={formData?.url}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className="my-2 px-2" style={{}}>
                      attachment:
                    </h4>
                    {/* <input type="text" style={{ fontSize: "x-large", outline: 'none', width: "80%" }} className="input-box rounded-pill p-1 border-0" value={attachments} readOnly /> */}
                    {/* <a href={attachments}>Click here</a> */}
                    <DisplayAttachments campaigns={campaigns} />
                  </div>
                </div>
              </div>
              <div className="card mt-5 border-0">
                <h4 className="mt-2 text-center" style={{ color: "#120c34" }}>
                  campaign details
                </h4>
                <div
                  className="card-body border-0 text-center"
                  style={{ background: "#120c34", borderRadius: "25px" }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="text-center text-white" style={{}}>
                        maximum audience
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.maximumAudience}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <h3 className="text-center text-white" style={{}}>
                        cost per target
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          color: "#120c34",
                        }}
                        value={formData?.costPerTarget}
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>

                    {/* <div className="col-md-12 mt-3">
                                            <h3 className='text-center text-white' style={{}}>participants audience size</h3>
                                            <div className="d-flex">
                                                <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={participantAudienceSizeMIN} className="input-box rounded-pill text-center" readOnly />
                                                <h3 className='text-center text-white' style={{}}>to</h3>
                                                <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={participantAudienceSizeMAX} className="input-box rounded-pill text-center" readOnly />
                                            </div>
                                        </div> */}
                    <div className="col-md-12 mt-3">
                      <h3 className="text-center text-white" style={{}}>
                        deliverables per participants
                      </h3>
                      <input
                        type="text"
                        style={{
                          border: "#120c34 2px solid ",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "50%",
                          color: "#120c34",
                        }}
                        value="1-10"
                        className="input-box rounded-pill text-center"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
