import React from "react";

const CustomButton = ({ label, ...props }) => {
  return (
    <button {...props} class="custom-btn cursor-pointer">
      {label}
    </button>
  );
};

export default CustomButton;
