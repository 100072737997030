import './App.css';
import './external-css/library.css'
import Navbar from './components/Navbar';
import Dashboard from './components/MarketPlace/Dashboard';
import Favorites from './components/MarketPlace/Favorites';
import Library from './components/Content/Library';
import Signup from './components/Authentication/Signup';
import Signin from './components/Authentication/Signin';
import Dashboard_main from './components/Dashboard_main';
import StripeContainer from './Payment/Payment_form';

import SignUpFormCreator from './components/Authentication/Signup_Creator';

import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Routes
} from "react-router-dom";
import Campaign_Create from './components/Campaign/Campaign_Create';
import Campaign_Dashboard from './components/Campaign/Campaign_Dashboard';
import Campaign_Manage from './components/Campaign/Campaign_Manage';
import Manage_req from './components/MarketPlace/Manage_req';
import Dashboard_creator from './components/Dashboard_creator'
import All_campaign from './components/Creator_campaign/All_campaign'
import Active_campaign from './components/Creator_campaign/Active_campaign'
import Manage_campaign from './components/Creator_campaign/Manage_campaign';
import Creator_MarketPlace_Dashboard from './components/Creator_MarketPlace/Creator_MarketPlace_Dashboard';
import MaskedUrl from './components/Masked_URL/MaskedUrl';
import Profile_DashBoard from './components/Profile/Profile_DashBoard';
import Campaign_Edit from './components/Campaign/Campaign_Edit';




function App() {
  const isLogedin=window.localStorage.getItem("isLogedin")
  // console.log(isLogedin)
  return (
    <div>
      <Router>
        {/* <Navbar /> */}
        <Navigation isLogedin={isLogedin} />
        
        <Routes>
          <Route path="/" element={<Dashboard_main/>} />
          <Route path="/home" element={<Dashboard_creator/>} />
          <Route path="/home/all_campaign" element={<All_campaign/>} />
          <Route path='manage_campaigns' element={<Manage_campaign/>}/>
          <Route path="/home/active_campaign" element={<Active_campaign/>} />
          <Route path="/marketplace" element={<Dashboard />} />
          <Route path="/marketplace/favorites" element={<Favorites/>} />
          <Route path="/content_library" element={<Library/>} />
          <Route path="/signup" element={<Signup/>} />

          <Route path="/signup/creator" element={<SignUpFormCreator/>} />

          <Route path="/signin" element={<Signin/>} />
          <Route path="/campaigns_dashboard" element={<Campaign_Dashboard/>} />
          <Route path="/campaigns_create" element={<Campaign_Create/>} />
          <Route path="/campaigns_edit" element={<Campaign_Edit/>} />
          <Route path="/campaigns_manage" element={<Campaign_Manage/>} />
          <Route path="/manage_req" element={<Manage_req/>} />
          <Route path="/home/marketplace_request" element={<Creator_MarketPlace_Dashboard/>} />
          <Route path="/make-payment" element={<StripeContainer />} />

          <Route path="/view/:unique_code" element={<MaskedUrl/>} />
          <Route path="/profile/dashboard" element={<Profile_DashBoard/>} />
      </Routes>
      </Router>
    </div>
  );
}

function Navigation() {
  const location = useLocation();
  const isLogedin=window.localStorage.getItem("isLogedin")
  // console.log(isLogedin)

  // Render Navbar conditionally, hide it only on the signup page
  if (location.pathname === "/signup" || location.pathname === "/signin" || location.pathname === "/signup/creator" || location.pathname.startsWith("/view/") ) {
    return null;
  }

  return <Navbar isLogedin={isLogedin}  />;
}


export default App;
