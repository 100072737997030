import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';


export default class Dashboard_main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      account_type: "1",
      selected_creators: 0,
      isActive: false,
      showForm: false,
      currentStep: 1,
      startDate: '',
      requestType: '',
      Platform: '',
      requestInstructionsText: '',
      WebsiteUrl: '',
      file: null,
      count: 0
    };
  }
  isLogedin = () => {
    return window.localStorage.getItem("isLogedin"); // Convert string to boolean
  }
  checkAccount = () => {
    if (this.state.account_type == window.localStorage.getItem("account")) {
      // console.log("matched", this.state.account_type, window.localStorage.getItem("account"));
      return true;
    }
  }

  componentDidMount() {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
      const headers = new Headers({
        'Authorization': `Bearer ${token}` // Set the Authorization header with the bearer token
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/user`, {
          method: 'GET',
          headers: headers
        });
        const result = await response.json();

        if (response.ok && result.status === 'SUCCESS') {
          this.setState({ data: result.data });
          // this.setState({ account_type: result.data.user_role });
        } else {
          if (result.code === 'token_not_valid') {
            window.localStorage.removeItem("refreshToken")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("userName")
            window.localStorage.removeItem("isLogedin")
            console.error('Token is invalid or expired');
            window.location.href = '/signin'; // Automatically redirect to /signin
          } else {
            throw new Error('Failed to fetch data');
          }
        }
      } catch (error) {
        this.setState({ error: error.message });
        console.error(error.message);
      }
    };

    fetchData();
  }













  render() {
    if (!this.isLogedin()) {
      // Redirect to /signin if not logged in
      return <Navigate to="/signin" replace />;
    }
    if (!this.checkAccount()) {
      // console.log("matched and runned");
      return <Navigate to="/" replace />;
    }
    const randomNumber1 = Math.floor(Math.random() * 100);
    const randomNumber2 = Math.floor(Math.random() * 100);
    const randomNumber3 = Math.floor(Math.random() * 100);
    const { data } = this.state;
    return (
      <div>
        <div className="container-fluid mt-3 px-md-5">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <h3 className='px-2 mb-4'>welcome back,<b style={{ textTransform: 'uppercase' }}>{data.name}!</b></h3>
              <div className="card border-0 text-white" style={{ background: "#120c34", borderRadius: "25px" }}>
                <div className="card-body d-flex justify-content-between align-items-center border-0 p-2 mx-2">
                  <div className="text-left">
                    <h4 style={{ fontSize: "2.1rem" }}>$100,987</h4>
                    <p className=" mb-0">current balance</p>
                  </div>
                  <button className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block'
                    }}>
                      Manage
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 ">
              <h3 className='px-md-2 mb-4'>Performance Snapshot</h3>
              <div className="justify-content-between">
                <div className="row">
                  <div className="col-3 col-sm-4 ">
                    <input
                      type="text"
                      className="form-control input-custom bg-transparent"
                      placeholder="Input 1"
                      value={randomNumber1}
                      style={{ fontSize: "2.1rem", color: "#120c34" }}
                      readOnly
                    />
                    <small style={{ color: "grey" }}>Impression</small>
                  </div>
                  <div className="col-3 col-sm-4">
                    <input
                      type="text"
                      className="form-control input-custom bg-transparent"
                      placeholder="Input 1"
                      value={randomNumber1}
                      style={{ fontSize: "2.1rem", color: "#120c34" }}
                      readOnly
                    />
                    <small style={{ color: "grey" }}>Clicks</small>
                  </div>
                  <div className="col-3 col-sm-4">
                    <input
                      type="text"
                      className="form-control input-custom bg-transparent"
                      placeholder="Input 3"
                      value={randomNumber3}
                      style={{ fontSize: "2.1rem", color: "#120c34" }}
                      readOnly
                    />
                    <small style={{ color: "grey" }}>Posts</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        

      </div>

    )
  }
}
