import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import MultiSelectDropdown from "../Campaign/MultiSelectDropdown";
import ModalComponent from "../../CommonComponents/ModalComponent";
import PasswordChangeForm from "./PasswordChange";
import axios from "axios";
import { toast } from "react-toastify";

export default class Profile_DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: null,
      isEditing: false,
      editedData: {},
      states: [],
      interestsData: [],
      showModal: false,
      uploadDataUrl: [],
      imagePreview: null,
      isUploading: false,
    };
  }

  handleShow = () => this.setState({ showModal: true });
  handleClose = () => this.setState({ showModal: false });

  isLogedin = () => {
    return localStorage.getItem("isLogedin") === "true";
  };

  fetchStates = async () => {
    try {
      const response = await fetch(
        "https://api.embassy.io/api/general/v1/state"
      );
      if (response.ok) {
        const result = await response.json();
        if (result.status === "SUCCESS") {
          const apiOptions = result.data?.map((item) => ({
            value: item.id,
            label: item.text,
          }));
          this.setState({ states: apiOptions });
        } else {
          throw new Error("Failed to fetch states");
        }
      } else {
        throw new Error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
      this.setState({ error: "Failed to load states. Please try again." });
    }
  };

  handleImageChange = (event) => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      this.setState({ imagePreview: URL.createObjectURL(file) }); // Preview the image
      this.handleImageUpload(file);
    } else {
      this.setState({ imagePreview: null }); // Reset image preview
    }
  };

  handleImageUpload = async (file) => {
    const token = localStorage.getItem("accessToken");

    if (file) {
      const fileCountByExtension = file.name.split(".").pop();
      const uploadUrlBody = {
        count: [
          {
            extension: fileCountByExtension,
            count: 1,
          },
        ],
      };

      const uploadUrlOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(uploadUrlBody),
      };

      this.setState({ isUploading: true });

      try {
        const uploadUrlResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/general/v1/upload-url`,
          uploadUrlOptions
        );
        const uploadUrlData = await uploadUrlResponse.json();

        if (!uploadUrlResponse.ok) {
          throw new Error(
            `Failed to obtain upload URLs: ${
              uploadUrlData.error || "Unknown Error"
            }`
          );
        }

        // console.log('Upload URLs:', uploadUrlData);
        if (uploadUrlData.status === "SUCCESS") {
          // Upload each file to the provided url
          this.setState({ uploadDataUrl: uploadUrlData.data });
          const attachments = uploadUrlData.data.map((item) => item.key);
          await this.uploadFileToAzure(file, uploadUrlData.data?.[0].url);
          this.setState({ isUploading: false });
        }
      } catch (error) {
        console.error("Error fetching upload URLs:", error);
        return; // Stop the submission if file upload URLs cannot be obtained
      }
    }
  };

  uploadFileToAzure = async (file, sasUrl) => {
    try {
      const response = await fetch(sasUrl, {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": file.type || "application/octet-stream",
        },
        body: file,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file.");
      }
      // console.log('File uploaded successfully to:', sasUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Rethrow to handle in the calling function
    }
  };

  fetchInterests = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/general/v1/interest`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const apiOptions = response.data.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        this.setState(
          {
            interestsData: apiOptions, // Assuming data is in `data` field
            loading: false,
          },
          () => this.updateInterestData()
        );
      })
      .catch((error) => {
        this.setState({
          error: "Failed to fetch data",
          loading: false,
        });
        console.error(error);
      });
  };

  handleStateChange = (selectedState) => {
    // const selectedStateId = event.target.value;
    // const selectedState = this.state.states.find(
    //   (state) => state.id === parseInt(selectedStateId)
    // );
    this.setState((prevState) => ({
      editedData: {
        ...prevState.editedData,
        state: selectedState ? selectedState : prevState.editedData.state,
      },
    }));
  };

  handleInterestChange = (selected) => {
    this.setState((prevState) => ({
      editedData: {
        ...prevState.editedData,
        interests: selected,
      },
    }));
  };

  backButtonStyle = {
    background: "none",
    border: "none",
    color: "inherit",
    padding: 0,
    fontSize: "inherit",
    cursor: "pointer",
    marginBottom: "5px",
    fontSize: "25px",
  };

  componentDidMount() {
    if (!this.isLogedin()) {
      window.location.href = "/signin";
      return;
    }

    this.fetchData();
    this.fetchStates();
    this.fetchInterests();
  }

  updateInterestData = () => {
    if (
      this.state.interestsData?.length > 0 &&
      this.state.editedData?.interests?.length > 0 &&
      typeof this.state.editedData?.interests[0] !== "object"
    ) {
      const interests = this.state.editedData?.interests;
      const interestArray = this.state.interestsData;
      // Map through the interests array (array of IDs) and find the corresponding object from interestArray
      let result = [];
      interests?.map((interestId) => {
        // Find the first object where the `id` matches the current interestId
        interestArray.forEach((interest) => {
          if (interest.label === interestId) {
            result.push(interest);
          }
        });
      });
      this.setState({ interests: result });
      this.setState((prevState) => ({
        editedData: {
          ...prevState.editedData,
          interests: result,
        },
      }));
    }
  };

  fetchData = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      window.location.href = "/signin";
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/auth/v1/user`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.status === "SUCCESS") {
          this.setState(
            {
              data: {
                ...result.data,
                state: {
                  value: result?.data?.state?.id,
                  label: result?.data?.state?.text,
                },
              },
              editedData: {
                ...result.data,
                state: {
                  value: result?.data?.state?.id,
                  label: result?.data?.state?.text,
                },
              },
            },
            () => this.updateInterestData()
          );
        } else if (result.code === "token_not_valid") {
          this.handleLogout();
        } else {
          throw new Error("Failed to fetch data");
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      this.setState({ error: error.message });
      console.error(error.message);
    }
  };

  handleLogout = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("isLogedin");
    console.error("Token is invalid or expired");
    window.location.href = "/signin";
  };

  toggleEdit = () => {
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  handleInputChange = (field, value) => {
    this.setState((prevState) => ({
      editedData: {
        ...prevState.editedData,
        [field]: value,
      },
    }));
  };

  handleSubmit = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      window.location.href = "/signin";
      return;
    }
    try {
      const data = this.state.editedData;
      const editedDataWithGenderCode = {
        mobile_number: data?.mobile_number,
        is_active: data?.status === "Active" ? 1 : 0,
        location: data?.location,
        images:
          this.state?.uploadDataUrl?.length > 0
            ? this.state?.uploadDataUrl?.map((item) => item.key)
            : this.state?.images_key,
        dob: data?.dob,
        social: data?.social,
        state: data?.state?.value,
        interests: data?.interests?.map((item) => item.value),
        gender:
          this.state.editedData.gender === "Male"
            ? 1
            : this.state.editedData.gender === "Female"
            ? 2
            : this.state.editedData.gender,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/auth/v1/user/${this.state.data.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify(editedDataWithGenderCode),
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.status === "SUCCESS") {
          this.setState({ data: this.state.editedData, isEditing: false }, () =>
            this.fetchData()
          );
          toast.success(result?.message);
          window.location.href = "/profile/dashboard";
        } else {
          throw new Error("Failed to update profile");
        }
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      this.setState({ error: error.message });
      console.error(error.message);
    }
  };

  render() {
    const { data, error, isEditing, editedData, states } = this.state;
    console.log(this.state, "ssjhsjkh");
    return (
      <div className="container-fluid">
        <div className="row mt-2 d-flex align-items-center">
          <div className="col-md-1">
            <button
              onClick={() => window.history.back()}
              style={this.backButtonStyle}
              className="d-flex align-items-center"
            >
              <i
                className="fa fa-arrow-left mr-1"
                style={{ fontSize: "13px" }}
              ></i>
              back
            </button>
          </div>
          <div className="offset-2 col-md-6 text-center"></div>
          <div
            className="col-md-3 "
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              onClick={isEditing ? this.handleSubmit : this.toggleEdit}
              className="btn bg-transparent"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                border: "#120c34 1px solid",
                borderRadius: "25px",
                fontSize: "x-large",
              }}
              disabled={this.state.isUploading}
            >
              <i className={`fa-solid ${isEditing ? "fa-save" : "fa-pen"}`}></i>
              {isEditing ? "save profile" : "edit profile"}
            </button>
          </div>
        </div>
        <div className="container-fluid" style={{ width: "90%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 className="text-center" style={{ color: "#120c34" }}>
              account settings{" "}
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isEditing ? (
              <div
                style={{
                  position: "relative",
                }}
              >
                <img
                  className="img-fluid "
                  src={this.state.imagePreview || data.images}
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    border: "#120c34 solid 4px",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  onClick={() => this.fileInput.click()}
                />
                <i
                  className="fa fa-camera"
                  style={{
                    position: "absolute",
                    bottom: "2px",
                    right: "85px",
                    fontSize: "24px",
                  }}
                  aria-hidden="true"
                >
                  <input
                    hidden
                    type="file"
                    style={{ display: "none" }} // Hide the file input
                    ref={(input) => (this.fileInput = input)} // Ref to the file input
                    accept="image/*" // Accept only image files
                    onChange={this.handleImageChange}
                  />
                </i>
              </div>
            ) : (
              <img
                className="img-fluid "
                src={data.images}
                alt=""
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  border: "#120c34 solid 4px",
                  objectFit: "contain",
                }}
              />
            )}
          </div>
          <h2 className="text-center mt-3" style={{ color: "#120c34" }}>
            {data.name}
          </h2>

          <div className="row">
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3 className="text-center mt-3" style={{ color: "#120c34" }}>
                  {data.email}
                </h3>
              </div>
            </div>
          </div>

          <div className="row my-5 ">
            <div className="col-4" style={{ width: "80%" }}>
              <div>
                <h3 className=" mt-3" style={{ color: "#120c34" }}>
                  settings
                </h3>
                <div
                  className="text-center"
                  style={{
                    borderRadius: "25px",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                >
                  <button
                    onClick={() => this.handleShow()}
                    className="btn btn-lg"
                    style={{
                      width: "100%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "large",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      change password
                    </span>
                  </button>
                </div>
              </div>
              <div>
                <h3 className=" mt-5 " style={{ color: "#120c34" }}>
                  gender
                </h3>
                {isEditing ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                  >
                    <input
                      onClick={(e) =>
                        this.handleInputChange("gender", e.target.value)
                      }
                      type="button"
                      className="set_wid set_btn_font input-box rounded-pill p-2 text-center "
                      style={{
                        color:
                          editedData.gender === "Male" ? "white" : "#120c34",
                        backgroundColor:
                          editedData.gender === "Male" ? "#120c34" : "white",
                        borderRadius: "25px",
                        border: "#120c34 2px solid",
                      }}
                      value="Male"
                    />
                    <input
                      onClick={(e) =>
                        this.handleInputChange("gender", e.target.value)
                      }
                      type="button"
                      className="set_wid set_btn_font input-box rounded-pill p-2 text-center "
                      style={{
                        color:
                          editedData.gender === "Female" ? "white" : "#120c34",
                        backgroundColor:
                          editedData.gender === "Female" ? "#120c34" : "white",
                        borderRadius: "25px",
                        border: "#120c34 2px solid",
                      }}
                      value="Female"
                    />
                  </div>
                ) : (
                  <div
                    className=""
                    style={{
                      border: "#120c34 2px solid",
                      borderRadius: "25px",
                      width: "100%",
                      maxWidth: "400px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        fontSize: "x-large",
                        outline: "none",
                        borderRadius: "25px",
                        width: "100%",
                        color: "#120c34",
                      }}
                      className="input-box rounded-pill p-1 border-0 text-center"
                      value={editedData.gender}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-4">
              <div>
                <h3 className=" mt-3" style={{ color: "#120c34" }}>
                  date of birth
                </h3>
                <div
                  className=""
                  style={{
                    border: "#120c34 2px solid",
                    borderRadius: "25px",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                >
                  {isEditing ? (
                    <DatePicker
                      selected={
                        editedData.dob ? new Date(editedData.dob) : null
                      }
                      style={{ width: "100%" }}
                      onChange={(date) => this.handleInputChange("dob", date)}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="mm/dd/yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={100}
                      customInput={
                        <input
                          readOnly
                          type="text"
                          style={{
                            fontSize: "x-large",
                            outline: "none",
                            borderRadius: "25px",
                            width: "100%",
                            color: "#120c34",
                            textAlign: "center",
                          }}
                          className="input-box rounded-pill p-1 border-0 text-center"
                        />
                      }
                    />
                  ) : (
                    <input
                      type="text"
                      style={{
                        fontSize: "x-large",
                        outline: "none",
                        borderRadius: "25px",
                        width: "100%",
                        color: "#120c34",
                        cursor: "default",
                      }}
                      className="input-box rounded-pill p-1 border-0 text-center"
                      value={
                        editedData.dob
                          ? format(new Date(editedData.dob), "MM/dd/yyyy")
                          : "Not set"
                      }
                      readOnly
                    />
                  )}
                </div>
              </div>

              <div>
                <h3 className=" mt-5" style={{ color: "#120c34" }}>
                  interests
                </h3>
                <div
                  className=""
                  style={{
                    border: !isEditing ? "#120c34 2px solid" : "none",
                    borderRadius: "25px",
                    width: !isEditing ? "100%" : "none",
                    maxWidth: "400px",
                  }}
                >
                  {isEditing ? (
                    <MultiSelectDropdown
                      styleDesign={{
                        border: "#120c34 2px solid !important",
                        borderRadius: "25px !important",
                        width: "100% !important",
                        maxWidth: "400px !important",
                      }}
                      multi={true}
                      data={this.state.interestsData}
                      placeholder={"Select interest..."}
                      handleChange={this.handleInterestChange}
                      value={editedData?.interests}
                    />
                  ) : (
                    <input
                      type="text"
                      style={{
                        fontSize: "x-large",
                        outline: "none",
                        borderRadius: "25px",
                        cursor: "default",
                        width: "100%",
                        color: "#120c34",
                      }}
                      className="input-box rounded-pill p-1 border-0 text-center"
                      value={
                        editedData.interests?.length > 0
                          ? editedData.interests
                              ?.map((option) => option?.label)
                              .join(", ")
                          : "Not set"
                      }
                      readOnly
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div>
                <h3 className=" mt-3" style={{ color: "#120c34" }}>
                  mobile
                </h3>
                <div
                  className=""
                  style={{
                    border: "#120c34 2px solid",
                    borderRadius: "25px",
                    width: "100%",
                    maxWidth: "400px",
                  }}
                >
                  <input
                    type="number"
                    style={{
                      fontSize: "x-large",
                      outline: "none",
                      borderRadius: "25px",
                      width: "100%",
                      color: "#120c34",
                      cursor: "default",
                    }}
                    className="input-box rounded-pill p-1 border-0 text-center"
                    value={editedData.mobile_number}
                    onChange={(e) => {
                      // Get the input value
                      const newValue = e.target.value;

                      // Check if the length exceeds 12 digits and prevent input if it does
                      if (newValue.length <= 12) {
                        this.handleInputChange("mobile_number", newValue);
                      }
                    }}
                    readOnly={!isEditing}
                  />
                </div>
              </div>
              <div>
                <h3 className=" mt-5" style={{ color: "#120c34" }}>
                  location
                </h3>
                <div
                  className=""
                  style={{
                    border: !isEditing ? "#120c34 2px solid" : "none",
                    borderRadius: "25px",
                    width: !isEditing ? "100%" : "none",
                    maxWidth: "400px",
                  }}
                >
                  {isEditing ? (
                    <MultiSelectDropdown
                      styleDesign={{
                        border: "#120c34 2px solid !important",
                        borderRadius: "25px !important",
                        width: "100% !important",
                        maxWidth: "400px !important",
                      }}
                      data={this.state.states}
                      placeholder={"Select locations..."}
                      handleChange={this.handleStateChange}
                      value={editedData?.state}
                    />
                  ) : (
                    <input
                      type="text"
                      style={{
                        fontSize: "x-large",
                        outline: "none",
                        borderRadius: "25px",
                        width: "100%",
                        color: "#120c34",
                        cursor: "default",
                      }}
                      className="input-box rounded-pill p-1 border-0 text-center"
                      value={
                        editedData.state ? editedData.state?.label : "Not set"
                      }
                      readOnly
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalComponent
          show={this.state.showModal}
          onHide={this.handleClose}
          title="change password"
          bodyContent={<PasswordChangeForm handleClose={this.handleClose} />}
        />
      </div>
    );
  }
}
