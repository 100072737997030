import React, { Component, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./campaign_create.css";
import { Navigate, useNavigate } from "react-router-dom";
import Errors from "../../CommonComponents/Errors";
import MultiSelectDropdown from "./MultiSelectDropdown";
import axios from "axios";

export default class Campaign_Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      contentGoal: "",
      campaignTitle: "",
      startDate: "",
      endDate: "",
      campaignDesc: "",
      url: "",
      attachment: "",
      fileCount: 0,
      Platform: "",
      contentFormate: "",
      minimumDileverable: "",
      minAge: "",
      maxAge: "",
      location: "",
      gender: "",
      interests: [],
      maximumBudget: "",
      maximumAudience: "",
      participantAudienceSizeMIN: "",
      participantAudienceSizeMAX: "",
      estimatedParticipants: "",
      costPerTarget: 0,

      selectedOptions: [],
      locationData: [],
      currentStep: 1,
      errors: {},
      interestOptions: null,
      shouldNavigate: false,
    };
    this.formRef = React.createRef(); // Create a reference to the form
  }

  handleFileChange = (event) => {
    const files = event.target.files; // Capture the new files from the input
    const fileCount = files.length; // Get the number of new files

    // Append the new files to the existing files array
    this.setState((prevState) => {
      return {
        fileCount: prevState.fileCount + fileCount, // Update the total count
        files: [...prevState.files, ...Array.from(files)], // Combine old and new files
      };
    });
  };

  isLogedin = () => {
    const isLogedin = window.localStorage.getItem("isLogedin");
    // console.log(isLogedin)
    return isLogedin;
  };
  handleExpInput = (event) => {
    let input = event.target.value.replace(/[^\d]/g, ""); // Remove non-digits

    // Automatically add slashes
    if (input.length > 2 && input.length <= 4) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }
    if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    // Validate the input before returning
    if (/^\d{0,2}\/?\d{0,2}\/?\d{0,4}$/.test(input)) {
      return input; // Return the formatted date
    }
    return event.target.value; // Return original input if it doesn't match the format
  };

  onDate1 = (event) => {
    const formattedDate = this.handleExpInput(event); // Get the formatted date
    this.handleInputChange("startDate", formattedDate);
  };
  onDate2 = (event) => {
    const formattedDate = this.handleExpInput(event); // Get the formatted date
    this.handleInputChange("endDate", formattedDate);
  };

  // handleInputChange = (key, value) => {
  //   this.setState({ [key]: value }, () => {
  //     // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
  //   });
  // }
  handleInputChange = (field, value) => {
    // console.log(field, value); // Add this line to debug
    this.setState({
      [field]: value,
    });
  };
  nextStep = (event) => {
    event.preventDefault();
    const { currentStep } = this.state;
    let valid = true;
    const errors = {}; // To collect error messages for each field

    try {
      switch (currentStep) {
        case 1:
          if (!this.state.contentGoal) {
            errors.contentGoal = "Please select a campaign goal.";
            valid = false;
          }
          break;
        case 2:
          if (!this.state.campaignTitle) {
            errors.campaignTitle = "Please enter a campaign title.";
            valid = false;
          }
          if (!this.state.startDate) {
            errors.startDate = "Please select a start date.";
            valid = false;
          }
          if (!this.state.endDate) {
            errors.endDate = "Please select an end date.";
            valid = false;
          }
          if (!this.state.campaignDesc) {
            errors.campaignDesc = "Please enter a campaign description.";
            valid = false;
          }
          if (!this.state.url) {
            errors.url = "Please enter a url.";
            valid = false;
          } else {
            // Validate dates (you may want to implement a helper function)
            this.convertDate(this.state.startDate);
            this.convertDate(this.state.endDate);
          }
          break;
        case 3:
          if (this.state.contentGoal === "Content") {
            this.setState({ Platform: "" });
            if (!this.state.contentFormate) {
              errors.contentFormate = "Please select content format.";
              valid = false;
            }
            if (!this.state.minimumDileverable) {
              errors.minimumDileverable = "Please provide minimum deliverable.";
              valid = false;
            }
          } else {
            if (!this.state.Platform) {
              errors.Platform = "Please select a platform.";
              valid = false;
            }
            if (!this.state.contentFormate) {
              errors.contentFormate = "Please select content format.";
              valid = false;
            }
            if (!this.state.minimumDileverable) {
              errors.minimumDileverable = "Please provide minimum deliverable.";
              valid = false;
            }
          }
          break;
        case 4:
          if (!this.state.minAge || !this.state.maxAge) {
            errors.age = "Please select a min and max age.";
            valid = false;
          }

          if (!this.state.location) {
            errors.location = "Please select a location.";
            valid = false;
          }
          if (!this.state.gender) {
            errors.gender = "Please select a gender.";
            valid = false;
          }
          if (!this.state.interests) {
            errors.interests = "Please select interests.";
            valid = false;
          }
          // age validation
          if (
            this.state.minAge < 18 ||
            this.state.minAge > 100 ||
            this.state.maxAge < 18 ||
            this.state.maxAge > 100
          ) {
            errors.age = "age must be between 18 and 100.";
            valid = false;
          }
          break;
        case 5:
          if (!this.state.maximumBudget) {
            errors.maximumBudget = "Please enter the maximum budget.";
            valid = false;
          }
          if (!this.state.maximumAudience) {
            errors.maximumAudience = "Please enter the maximum audience size.";
            valid = false;
          }
          if (
            !this.state.participantAudienceSizeMIN ||
            !this.state.participantAudienceSizeMAX
          ) {
            errors.participantSize =
              "Please enter the min and max participant audience size.";
            valid = false;
          }
          if (!this.state.estimatedParticipants) {
            errors.estimatedParticipants =
              "Please enter the estimated number of participants.";
            valid = false;
          }
          if (!this.state.costPerTarget) {
            errors.costPerTarget = "Please enter the cost per target.";
            valid = false;
          }
          break;
        default:
          break;
      }
      if (valid) {
        this.setState((prevState) => ({
          currentStep: prevState.currentStep + 1,
          errors: {}, // Reset errors on successful validation
        }));
        if (currentStep + 1 > 5) this.formRef.current.requestSubmit();
      } else {
        // If there are validation errors, update the errors state
        this.setState({ errors });
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };
  prevStep = () => {
    if (this.state.currentStep === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 1,
      }));
    }
  };

  convertDate = (inputDate) => {
    // Parse the date from MM/DD/YYYY format
    const dateParts = inputDate.split("/");
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1; // Adjust month from 1-12 to 0-11 for JS Date
    const day = parseInt(dateParts[1], 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Format the date as YYYY-MM-DDT00:00:00.000000Z
    const isoString = date.toISOString(); // Returns YYYY-MM-DDTHH:MM:SS.sssZ
    const startDatetime = isoString.split("T")[0] + "T00:00:00.000000Z"; // Restrict time to 00:00:00.000000Z

    // Example to show how to use it in state, if needed
    // this.setState({ start_datetime: startDatetime });

    return startDatetime;
  };

  handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission behavior
    // console.log("Form Data:", this.state);

    const files = this.state.files;
    // Retrieve the access token from local storage
    const token = localStorage.getItem("accessToken");

    // Fetch upload URLs for attachments if files are to be uploaded
    const fileCount = this.state.fileCount; // Assuming you have fileCount in your state
    let attachments = [];

    const uploadFileToAzure = async (file, sasUrl) => {
      try {
        const response = await fetch(sasUrl, {
          method: "PUT",
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": file.type || "application/octet-stream",
          },
          body: file,
        });

        if (!response.ok) {
          throw new Error("Failed to upload file.");
        }
        // console.log('File uploaded successfully to:', sasUrl);
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error; // Rethrow to handle in the calling function
      }
    };

    // Function to get upload URLs from the API
    if (files.length > 0) {
      // Create an object to count files by extension
      const fileCountByExtension = files.reduce((acc, file) => {
        const ext = file.name.split(".").pop();
        acc[ext] = (acc[ext] || 0) + 1;
        return acc;
      }, {});

      // Convert the object into the required format for the API
      const uploadUrlBody = {
        count: Object.entries(fileCountByExtension).map(
          ([extension, count]) => ({
            extension,
            count,
          })
        ),
      };

      const uploadUrlOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(uploadUrlBody),
      };

      try {
        const uploadUrlResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/general/v1/upload-url`,
          uploadUrlOptions
        );
        const uploadUrlData = await uploadUrlResponse.json();

        if (!uploadUrlResponse.ok) {
          throw new Error(
            `Failed to obtain upload URLs: ${
              uploadUrlData.error || "Unknown Error"
            }`
          );
        }

        // console.log('Upload URLs:', uploadUrlData);
        if (uploadUrlData.status === "SUCCESS") {
          // Upload each file to the provided url
          attachments = uploadUrlData.data.map((item) => item.key);
          for (let i = 0; i < files.length; i++) {
            await uploadFileToAzure(files[i], uploadUrlData.data[i].url);
          }
        }
      } catch (error) {
        console.error("Error fetching upload URLs:", error);
        return; // Stop the submission if file upload URLs cannot be obtained
      }
    }

    // Construct the request body with data from your component's state and obtained attachments
    const requestBody = {
      title: this.state.campaignTitle,
      status: 3,
      creator_id: 1,
      brand_id: 1,
      start_datetime: this.convertDate(this.state.startDate),
      campaign_type: this.state.contentGoal === "Growth" ? 3 : 4,
      budget: parseInt(this.state.maximumBudget),
      details: {
        platform: this.state.Platform,
        description: this.state.campaignDesc,
        url: this.state.url,
        attachments: attachments,
        minimumDileverable: this.state.minimumDileverable,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        contentFormate: this.state.contentFormate,
        minAge: this.state.minAge,
        maxAge: this.state.maxAge,
        location: this.state.location?.map((item) => item.label),
        gender: this.state.gender,
        interests: this.state.interests?.map((item) => item.value),
        maximumAudience: this.state.maximumAudience,
        participantAudienceSizeMIN: this.state.participantAudienceSizeMIN,
        participantAudienceSizeMAX: this.state.participantAudienceSizeMAX,
        estimatedParticipants: this.state.estimatedParticipants,
        costPerTarget: this.state.costPerTarget,
      },
    };

    const fetchOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    };

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id"); // Extract `id` from query string

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/${id}`,
        fetchOptions
      );
      const responseData = await response.json();
      // console.log('API Response:', responseData);

      if (response.ok) {
        // console.log('Campaign created successfully:', responseData);
        toast.success("Campaign created successfully! ");
        this.setState({ shouldNavigate: true });
        // window.location.href = `/campaigns_manage?id=${id}`;
      } else {
        console.error("Failed to create campaign:", responseData);
        toast.error("Failed to create campaign! ");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  handleDateSelect = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    this.setState({ startDate: formattedDate });
  };
  handleDateSelect1 = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    this.setState({ endDate: formattedDate });
  };

  handleDateChange = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    this.setState({ startDate: date });
    this.handleInputFormChange("startDate", formattedDate);
  };

  handleDateChange1 = (date) => {
    const formattedDate = format(date, "MM/dd/yyyy");
    this.setState({ endDate: date });
    this.handleInputFormChange("endDate", formattedDate);
  };
  handleExpInput = (event) => {
    const date = new Date(event.target.value);
    return format(date, "MM/dd/yyyy");
  };

  handleInputFormChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]);
    });
  };

  // Fetch data when the component mounts
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id"); // Extract `id` from query string
    if (id) {
      this.fetchCampaignData(id); // Fetch campaign data using the `id`
    }
    this.fetchLocationOptions();
    this.fetchInterests();
  }

  handleLocationChange = (selected) => {
    // setSelectedOptions(selected);
    // const selectedLabels = selected
    //   ? selected.map((option) => option.label).join(", ")
    //   : "";

    this.handleInputFormChange("location", selected);
  };

  handleInterestsChange = (selected) => {
    // setSelectedOptions(selected);
    // const selectedLabels = selected
    //   ? selected.map((option) => option.label).join(", ")
    //   : "";

    this.handleInputFormChange("interests", selected);
  };


  updateLocationData = () => {
    if (
      this.state.location?.length > 0 &&
      typeof this.state.location?.[0] !== "object" &&
      this.state.locationData?.length > 0
    ) {
      const locationArray = this.state.locationData;
      const locationData = this.state.location;
      // Map through the interests array (array of IDs) and find the corresponding object from interestArray
      let result = [];
      locationData?.map((location) => {
        // Find the first object where the `id` matches the current location
        locationArray.forEach((loc) => {
          if (loc.label === location) {
            result.push(loc);
          }
        });
      });
      this.setState({ location: result });
    }
  };

  updateInterestData = () => {
    if (
      this.state.interestOptions?.length > 0 &&
      this.state.interests?.length > 0 &&
      typeof this.state.interests[0] !== "object"
    ) {
      const interests = this.state.interests;
      const interestArray = this.state.interestOptions;
      // Map through the interests array (array of IDs) and find the corresponding object from interestArray
      let result = [];
      interests?.map((interestId) => {
        // Find the first object where the `id` matches the current interestId
        interestArray.forEach((interest) => {
          if (interest.value === interestId) {
            result.push(interest);
          }
        });
      });
      this.setState({ interests: result });
    }
  };

  // Fetch campaign data based on `id`
  fetchCampaignData = (id) => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          campaignData: response.data.data, // Assuming data is in `data` field
          loading: false,
        });
        if (response.data.data) {
          const data = response.data.data;
          this.setState(
            {
              Platform: data?.details?.platform ?? "",
              post_frequency: data?.details?.post_frequency ?? "",
              description: data?.details?.description ?? "",
              attachments: data?.details?.attachments ?? "",
              url: data?.details?.url ?? "",
              gender: data?.details?.gender ?? "",
              maxAge: data?.details?.maxAge ?? "",
              minAge: data?.details?.minAge ?? "",
              endDate: data?.details?.endDate ?? "",
              location: data?.details?.location,
              interests: data?.details?.interests,
              startDate: data?.details?.startDate ?? "",
              costPerTarget: data?.details?.costPerTarget ?? "",
              contentFormate: data?.details?.contentFormate ?? "",
              maximumAudience: data?.details?.maximumAudience ?? "",
              minimumDileverable: data?.details?.minimumDileverable ?? "",
              estimatedParticipants: data?.details?.estimatedParticipants ?? "",
              participantAudienceSizeMAX:
                data?.details?.participantAudienceSizeMAX ?? "",
              participantAudienceSizeMIN:
                data?.details?.participantAudienceSizeMIN ?? "",
              contentGoal: data.campaign_type === 3 ? "Growth" : "Content",
              campaignTitle: data.title ?? "",
              campaignDesc: data?.details?.description ?? "",
              maximumBudget: data.budget ?? 0,
            },
            () => {
              this.updateInterestData();
              this.updateLocationData();
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          error: "Failed to fetch data",
          loading: false,
        });
        console.error(error);
      });
  };

  // Fetch campaign data based on `id`
  fetchLocationOptions = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/general/v1/state`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const apiOptions = response.data.data?.map((item) => ({
          value: item.id,
          label: item.text,
        }));
        this.setState(
          {
            locationData: apiOptions, // Assuming data is in `data` field
            loading: false,
          },
          () => this.updateLocationData(this.state.locationData)
        );
      })
      .catch((error) => {
        this.setState({
          error: "Failed to fetch data",
          loading: false,
        });
        console.error(error);
      });
  };

  //fetch interests
  fetchInterests = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/general/v1/interest`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const apiOptions = response.data.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        this.setState(
          {
            interestOptions: apiOptions, // Assuming data is in `data` field
            loading: false,
          },
          () => this.updateInterestData()
        );
      })
      .catch((error) => {
        this.setState({
          error: "Failed to fetch data",
          loading: false,
        });
        console.error(error);
      });
  };

  renderForm(props) {
    const { currentStep, fileCount, location } = this.state; // Destructure state variables
    const selectedInterestNames = [];
    console.log(this.state, "ddkjdhjkdh");
    switch (currentStep) {
      case 1:
        return (
          // You need to add a return statement here
          <div>
            <div className="container text-center mb-5 px-5">
              <div
                id="campaign_progress"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1px",
                  marginBottom: "20px",
                }}
              >
                <li
                  className="active "
                  id="step1"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step2"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step3"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step4"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step5"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
              </div>
            </div>
            <div className="container text-center px-5 mb-5">
              <h3 className="mb-4">campaign goal</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  marginBottom: "20px",
                }}
              >
                <input
                  onClick={(e) =>
                    this.handleInputChange("contentGoal", e.target.value)
                  }
                  type="button"
                  name="contentGoal"
                  className="set_wid btn btn-lg rounded-pill mx-3"
                  style={{
                    color:
                      this.state.contentGoal === "Growth" ? "white" : "#120c34",
                    fontSize: "x-large",
                    backgroundColor:
                      this.state.contentGoal === "Growth" ? "#120c34" : "white",
                    borderRadius: "25px",
                    border: "#120c34 2px solid",
                  }}
                  value="Growth"
                />
                <input
                  onClick={(e) =>
                    this.handleInputChange("contentGoal", e.target.value)
                  }
                  type="button"
                  name="contentGoal"
                  className="set_wid btn btn-lg rounded-pill mx-3"
                  style={{
                    color:
                      this.state.contentGoal === "Content"
                        ? "white"
                        : "#120c34",
                    fontSize: "x-large",
                    backgroundColor:
                      this.state.contentGoal === "Content"
                        ? "#120c34"
                        : "white",
                    borderRadius: "25px",
                    border: "#120c34 2px solid",
                  }}
                  value="Content"
                />
              </div>
              <Errors msg={this.state.errors?.contentGoal} mid={true} />

              <p
                className="hide"
                style={{ color: "#120c34", fontSize: "x-large" }}
              >
                your customer will create content for your brand and <br /> post
                it on their own social media accounts{" "}
              </p>
              <p
                className="hidden-on-web"
                style={{ color: "#120c34", fontSize: "larger" }}
              >
                your customer will create content for your brand and post it on
                their own social media accounts{" "}
              </p>
              <div className="row text-end d-flex justify-content-end mr-md-5 px-md-5">
                <button
                  onClick={this.nextStep}
                  className="btn btn-lg"
                  style={{
                    letterSpacing: "-.02em",
                    textTransform: "none",
                    backgroundColor: "#3898ec",
                    backgroundImage:
                      "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                    borderRadius: "25px",
                    marginLeft: "-32px",
                    fontFamily: "sofia-pro,sans-serif",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    lineHeight: 1,
                    transition: "transform .3s",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "inherit",
                      cursor: "pointer",
                      border: "0",
                      borderRadius: "0",
                      padding: "9px 15px",
                      textDecoration: "none",
                      display: "inline-block",
                    }}
                  >
                    next step
                  </span>
                </button>
              </div>
            </div>
          </div>
        );
      // Cases for other steps should be defined here if they exist.
      case 2:
        return (
          <div>
            <div className="container text-center mb-5 px-5">
              <div
                id="campaign_progress"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1px",
                  marginBottom: "20px",
                }}
              >
                <li
                  id="step1"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="active"
                  id="step2"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step3"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step4"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step5"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
              </div>
            </div>

            <div className="container text-center ">
              <h3 className="mb-4">campaign details</h3>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    value={this.state.campaignTitle}
                    onChange={(e) =>
                      this.handleInputChange("campaignTitle", e.target.value)
                    }
                    style={{
                      borderRadius: "25px",
                      fontSize: "x-large",
                      width: "100%",
                      border: "#120c34 2px solid",
                    }}
                    placeholder="Campaign Title"
                    className="input-box rounded-pill p-1 px-4"
                  />
                  <Errors msg={this.state.errors?.campaignTitle} />
                </div>
                <div className="col-md-6 give_some_margin_top">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex flex-column">
                      {/* <input type="text" onChange={this.onDate1} value={this.state.startDate} style={{ border: "#120c34 2px solid ", borderRadius: "25px", width: "80%" }} placeholder="MM/DD/YYYY" className="set_btn_font  datepicker input-box rounded-pill p-1 text-center" /> */}
                      <DatePicker
                        selected={
                          this.state.startDate
                            ? parse(
                                this.state.startDate,
                                "MM/dd/yyyy",
                                new Date()
                              )
                            : null
                        }
                        onSelect={this.handleDateSelect}
                        onChange={this.handleDateChange}
                        dateFormat="MM/dd/yyyy" // Ensures date format is MM/DD/YYYY in the picker
                        placeholderText="mm/dd/yyyy"
                        minDate={new Date()}
                        customInput={
                          <input
                            type="text"
                            onChange={this.onDate1}
                            value={this.state.startDate}
                            style={{
                              border: "#120c34 2px solid",
                              borderRadius: "25px",
                              width: "80%",
                            }}
                            placeholder="MM/DD/YYYY"
                            className="set_btn_font datepicker input-box rounded-pill p-1 text-center"
                          />
                        }
                      />
                      <Errors msg={this.state?.errors?.startDate} />
                    </div>
                    <h3>to</h3>
                    <div className="d-flex flex-column">
                      {/* <input type="text" onChange={this.onDate2} value={this.state.endDate} style={{ borderRadius: "25px", width: "80%", border: "#120c34 2px solid" }} placeholder="MM/DD/YYYY" className="set_btn_font datepicker input-box rounded-pill p-1 text-center" /> */}
                      <DatePicker
                        selected={
                          this.state.endDate
                            ? parse(
                                this.state.endDate,
                                "MM/dd/yyyy",
                                new Date()
                              )
                            : null
                        }
                        onSelect={this.handleDateSelect1}
                        onChange={this.handleDateChange1}
                        minDate={this.state.startDate || new Date()}
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy" // Ensures date format is MM/DD/YYYY in the picker
                        customInput={
                          <input
                            type="text"
                            onChange={this.onDate2}
                            value={this.state.endDate}
                            style={{
                              border: "#120c34 2px solid",
                              borderRadius: "25px",
                              width: "80%",
                            }}
                            placeholder="MM/DD/YYYY"
                            className="set_btn_font datepicker input-box rounded-pill p-1 text-center"
                          />
                        }
                      />
                      <Errors msg={this.state?.errors?.endDate} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <textarea
                    value={this.state.campaignDesc}
                    onChange={(e) =>
                      this.handleInputChange("campaignDesc", e.target.value)
                    }
                    name=""
                    row="25"
                    id=""
                    style={{
                      borderRadius: "25px",
                      fontSize: "x-large",
                      width: "100%",
                      border: "#120c34 2px solid",
                    }}
                    className="input-box rounded-pill p-1  px-2 mt-3"
                    placeholder="campaign description..."
                  />
                  <Errors msg={this.state?.errors?.campaignDesc} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                    }}
                  >
                    <h4 className="my-2 px-2" style={{}}>
                      url:
                    </h4>
                    <input
                      type="text"
                      value={this.state.url}
                      onChange={(e) =>
                        this.handleInputChange("url", e.target.value)
                      }
                      style={{
                        fontSize: "x-large",
                        outline: "none",
                        width: "100%",
                        margin: "6px",
                      }}
                      className="input-box rounded-pill p-1 border-0"
                    />
                  </div>
                  <Errors msg={this.state?.errors?.url} />
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end give_some_margin_top">
                  <div
                    className="d-flex align-items-center px-3 text-end"
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                      width: "100%",
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput"
                      style={{ margin: "10px 0", display: "none" }}
                      multiple
                      onChange={this.handleFileChange}
                    />
                    <label
                      htmlFor="fileInput"
                      className="mb-0"
                      style={{ cursor: "pointer", color: "#120c34" }}
                    >
                      <div className="">
                        <span style={{ fontSize: "larger" }} className="my-2">
                          {" "}
                          {fileCount > 0
                            ? `(${fileCount} uploaded)`
                            : "add attachment (optional)"}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-3 mb-5">
                <div className="col-6 col-sm-6 d-flex justify-content-start">
                  <button
                    type="button"
                    onClick={this.prevStep}
                    className="give_wid btn bg-transparent btn-lg"
                    style={{
                      color: "#120c34",
                      border: "#120c34 3px solid",
                      borderRadius: "25px",
                    }}
                  >
                    previous step
                  </button>
                </div>
                <div className="col-6 col-sm-6 d-flex justify-content-end">
                  <button
                    className="btn btn-lg"
                    onClick={this.nextStep}
                    style={{
                      width: "100%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      next
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        if (this.state.contentGoal == "Content") {
          return (
            <div>
              <div className="container text-center mb-5 px-5">
                <div
                  id="campaign_progress"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1px",
                    marginBottom: "20px",
                  }}
                >
                  <li
                    id="step1"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    id="step2"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="active"
                    id="step3"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    id="step4"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    id="step5"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3 className="mt-5 text-center" style={{}}>
                      content format
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                        marginBottom: "20px",
                      }}
                    >
                      <input
                        onClick={(e) =>
                          this.handleInputChange(
                            "contentFormate",
                            e.target.value
                          )
                        }
                        type="button"
                        className="btn btn-lg rounded-pill mx-3"
                        style={{
                          color:
                            this.state.contentFormate === "Photo"
                              ? "white"
                              : "#120c34",
                          fontSize: "x-large",
                          backgroundColor:
                            this.state.contentFormate === "Photo"
                              ? "#120c34"
                              : "white",
                          width: "30%",
                          borderRadius: "25px",
                          border: "#120c34 2px solid",
                        }}
                        value="Photo"
                      />
                      <input
                        onClick={(e) =>
                          this.handleInputChange(
                            "contentFormate",
                            e.target.value
                          )
                        }
                        type="button"
                        className="btn btn-lg rounded-pill mx-3"
                        style={{
                          color:
                            this.state.contentFormate === "Video"
                              ? "white"
                              : "#120c34",
                          fontSize: "x-large",
                          backgroundColor:
                            this.state.contentFormate === "Video"
                              ? "#120c34"
                              : "white",
                          width: "30%",
                          borderRadius: "25px",
                          border: "#120c34 2px solid",
                        }}
                        value="Video"
                      />
                    </div>
                    <Errors
                      msg={this.state?.errors?.contentFormate}
                      mid={true}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <h3 className="text-center" style={{}}>
                      maximum deliverable
                    </h3>
                    <input
                      type="number"
                      value={this.state.minimumDileverable}
                      onChange={(e) =>
                        this.handleInputChange(
                          "minimumDileverable",
                          e.target.value
                        )
                      }
                      style={{
                        border: "#120c34 2px solid ",
                        borderRadius: "25px",
                        fontSize: "x-large",
                        width: "30%",
                      }}
                      placeholder="1-10"
                      className="input-box rounded-pill p-2 text-center"
                      min="1"
                      max="10"
                    />
                    <Errors
                      msg={this.state?.errors?.minimumDileverable}
                      mid={true}
                    />
                  </div>
                </div>

                <div className="row mt-3 mb-5">
                  <div className="col-6 col-sm-6 d-flex justify-content-start">
                    <button
                      type="button"
                      onClick={this.prevStep}
                      className="give_wid btn bg-transparent btn-lg"
                      style={{
                        color: "#120c34",
                        border: "#120c34 3px solid",
                        borderRadius: "25px",
                      }}
                    >
                      previous step
                    </button>
                  </div>
                  <div className="col-6 col-sm-6 d-flex justify-content-end">
                    <button
                      className="btn btn-lg"
                      onClick={this.nextStep}
                      style={{
                        width: "100%",
                        letterSpacing: "-.02em",
                        textTransform: "none",
                        backgroundColor: "#3898ec",
                        backgroundImage:
                          "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                        borderRadius: "25px",
                        marginLeft: "-32px",
                        fontFamily: "sofia-pro,sans-serif",
                        fontSize: "1.2em",
                        fontWeight: 600,
                        lineHeight: 1,
                        transition: "transform .3s",
                        display: "inline-block",
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          lineHeight: "inherit",
                          cursor: "pointer",
                          // backgroundColor: '#3898ec',
                          border: "0",
                          borderRadius: "0",
                          padding: "9px 15px",
                          textDecoration: "none",
                          display: "inline-block",
                        }}
                      >
                        next
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="container text-center mb-5 px-5">
                <div
                  id="campaign_progress"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1px",
                    marginBottom: "20px",
                  }}
                >
                  <li
                    id="step1"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    id="step2"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="active"
                    id="step3"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    id="step4"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    className="hide"
                    style={{
                      color: "#120c34",
                      fontSize: "30px",
                      listStyleType: "none",
                    }}
                  >
                    •
                  </li>
                  <li
                    id="step5"
                    style={{
                      color: "#120c34",
                      fontSize: "24px",
                      listStyleType: "none",
                    }}
                  ></li>
                </div>
              </div>
              <div className="container">
                <h3 className="mt-5 text-center" style={{}}>
                  platform
                </h3>
                <div className="row">
                  <div className="col-4 col-sm-4 text-center">
                    <label htmlFor="logo1">
                      <img
                        src="/images/instagram.png"
                        alt="Instagram Logo"
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputChange("Platform", e.target.value)
                        }
                        id="logo1"
                        name="Platform"
                        value="instagram"
                      />
                    </label>
                  </div>
                  <div className="col-4 col-sm-4 text-center">
                    <label htmlFor="logo2">
                      <img
                        src="/images/tiktok.png"
                        alt="TikTok Logo"
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputChange("Platform", e.target.value)
                        }
                        id="logo2"
                        name="Platform"
                        value="tiktok"
                      />
                    </label>
                  </div>
                  <div className="col-4 col-sm-4 text-center">
                    <label htmlFor="logo3">
                      <img
                        src="/images/youtube.png"
                        alt="YouTube Logo"
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <input
                        type="radio"
                        onClick={(e) =>
                          this.handleInputChange("Platform", e.target.value)
                        }
                        id="logo3"
                        name="Platform"
                        value="youtube"
                      />
                    </label>
                  </div>
                </div>
                <Errors msg={this.state?.errors?.Platform} />

                <div className="row mt-3">
                  <div className="col-md-6 text-center">
                    <h3 className="text-center" style={{}}>
                      content format
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                        marginBottom: "20px",
                      }}
                    >
                      <input
                        onClick={(e) =>
                          this.handleInputChange(
                            "contentFormate",
                            e.target.value
                          )
                        }
                        type="button"
                        className="btn btn-lg rounded-pill mx-3"
                        style={{
                          color:
                            this.state.contentFormate === "Photo"
                              ? "white"
                              : "#120c34",
                          fontSize: "x-large",
                          backgroundColor:
                            this.state.contentFormate === "Photo"
                              ? "#120c34"
                              : "white",
                          width: "30%",
                          borderRadius: "25px",
                          border: "#120c34 2px solid",
                        }}
                        value="Photo"
                      />
                      <input
                        onClick={(e) =>
                          this.handleInputChange(
                            "contentFormate",
                            e.target.value
                          )
                        }
                        type="button"
                        className="btn btn-lg rounded-pill mx-3"
                        style={{
                          color:
                            this.state.contentFormate === "Video"
                              ? "white"
                              : "#120c34",
                          fontSize: "x-large",
                          backgroundColor:
                            this.state.contentFormate === "Video"
                              ? "#120c34"
                              : "white",
                          width: "30%",
                          borderRadius: "25px",
                          border: "#120c34 2px solid",
                        }}
                        value="Video"
                      />
                    </div>
                    <Errors msg={this.state?.errors?.contentFormate} />
                  </div>
                  <div className="col-md-6 text-center">
                    <h3 className="text-center" style={{}}>
                      maximum deliverable
                    </h3>
                    <input
                      type="number"
                      value={this.state.minimumDileverable}
                      onChange={(e) =>
                        this.handleInputChange(
                          "minimumDileverable",
                          e.target.value
                        )
                      }
                      style={{
                        border: "#120c34 2px solid ",
                        borderRadius: "25px",
                        fontSize: "x-large",
                        width: "30%",
                      }}
                      placeholder="1-10"
                      className="input-box rounded-pill p-2 text-center"
                      min="1"
                      max="10"
                    />
                    <Errors msg={this.state?.errors?.minimumDileverable} />
                  </div>
                </div>

                <div className="row mt-3 mb-5">
                  <div className="col-6 col-sm-6 d-flex justify-content-start">
                    <button
                      type="button"
                      onClick={this.prevStep}
                      className="give_wid btn bg-transparent btn-lg"
                      style={{
                        color: "#120c34",
                        border: "#120c34 3px solid",
                        borderRadius: "25px",
                      }}
                    >
                      previous step
                    </button>
                  </div>
                  <div className="col-6 col-sm-6 d-flex justify-content-end">
                    <button
                      className="btn btn-lg"
                      onClick={this.nextStep}
                      style={{
                        width: "100%",
                        letterSpacing: "-.02em",
                        textTransform: "none",
                        backgroundColor: "#3898ec",
                        backgroundImage:
                          "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                        borderRadius: "25px",
                        marginLeft: "-32px",
                        fontFamily: "sofia-pro,sans-serif",
                        fontSize: "1.2em",
                        fontWeight: 600,
                        lineHeight: 1,
                        transition: "transform .3s",
                        display: "inline-block",
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          lineHeight: "inherit",
                          cursor: "pointer",
                          // backgroundColor: '#3898ec',
                          border: "0",
                          borderRadius: "0",
                          padding: "9px 15px",
                          textDecoration: "none",
                          display: "inline-block",
                        }}
                      >
                        next
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      case 4:
        return (
          <div>
            <div className="container text-center mb-5 px-5">
              <div
                id="campaign_progress"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1px",
                  marginBottom: "20px",
                }}
              >
                <li
                  id="step1"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step2"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step3"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="active"
                  id="step4"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step5"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
              </div>
            </div>
            <h3 className="text-center mb-2">target demographics</h3>
            <div className="container text-center  mt-4">
              <div className="row">
                <div className="col-md-6">
                  <h3 className=" text-center" style={{}}>
                    age
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <input
                      type="number"
                      value={this.state.minAge}
                      onChange={(e) =>
                        this.handleInputChange("minAge", e.target.value)
                      }
                      style={{
                        border: "#120c34 2px solid ",
                        borderRadius: "25px",
                      }}
                      min={18}
                      max={100}
                      placeholder="min"
                      className="set_wid set_btn_font input-box rounded-pill p-2 text-center "
                      required
                    />
                    <input
                      type="text"
                      value={this.state.maxAge}
                      onChange={(e) =>
                        this.handleInputChange("maxAge", e.target.value)
                      }
                      style={{
                        border: "#120c34 2px solid ",
                        borderRadius: "25px",
                      }}
                      placeholder="max"
                      className="set_wid set_btn_font input-box rounded-pill p-2 text-center"
                      required
                    />
                  </div>
                  <Errors msg={this.state?.errors?.age} mid={true} />
                </div>
                <div className="col-md-6 text-center">
                  <h3 className="text-center" style={{}}>
                    location
                  </h3>
                  {/* <input type="text" value={this.state.location} onChange={e => this.handleInputChange('location', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%" }} placeholder="ex: Los Angeles, CA" className="input-box rounded-pill p-2 text-center" /> */}
                  <MultiSelectDropdown
                    data={this.state.locationData}
                    placeholder={"Select locations..."}
                    multi={true}
                    handleChange={this.handleLocationChange}
                    value={this.state.location}
                  />
                  <Errors msg={this.state?.errors?.location} />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 text-center">
                  <h3 className="text-center" style={{}}>
                    gender
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <input
                      onClick={(e) =>
                        this.handleInputChange("gender", e.target.value)
                      }
                      type="button"
                      className="set_wid set_btn_font input-box rounded-pill p-2 text-center "
                      style={{
                        color:
                          this.state.gender === "Male" ? "white" : "#120c34",
                        backgroundColor:
                          this.state.gender === "Male" ? "#120c34" : "white",
                        borderRadius: "25px",
                        border: "#120c34 2px solid",
                      }}
                      value="Male"
                    />
                    <input
                      onClick={(e) =>
                        this.handleInputChange("gender", e.target.value)
                      }
                      type="button"
                      className="set_wid set_btn_font input-box rounded-pill p-2 text-center "
                      style={{
                        color:
                          this.state.gender === "Female" ? "white" : "#120c34",
                        backgroundColor:
                          this.state.gender === "Female" ? "#120c34" : "white",
                        borderRadius: "25px",
                        border: "#120c34 2px solid",
                      }}
                      value="Female"
                    />
                  </div>
                  <Errors msg={this.state?.errors?.gender} mid={true} />
                </div>
                <div className="col-md-6 text-center">
                  <h3 className="text-center" style={{}}>
                    interests
                  </h3>
                  {/* <input
                    type="text"
                    value={this.state.interests}
                    onChange={(e) =>
                      this.handleInputChange("interests", e.target.value)
                    }
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                      fontSize: "x-large",
                      width: "100%",
                    }}
                    placeholder="ex: Fitness"
                    className="input-box rounded-pill p-2 text-center"
                  /> */}
                  <MultiSelectDropdown
                    data={this.state.interestOptions}
                    placeholder={"select interests..."}
                    multi={true}
                    handleChange={this.handleInterestsChange}
                    value={this.state.interests}
                  />

                  <Errors msg={this.state?.errors?.interests} />
                </div>
              </div>

              <div className="row mt-3 mb-5">
                <div className="col-6 col-sm-6 d-flex justify-content-start">
                  <button
                    type="button"
                    onClick={this.prevStep}
                    className="give_wid btn bg-transparent btn-lg"
                    style={{
                      color: "#120c34",
                      border: "#120c34 3px solid",
                      borderRadius: "25px",
                    }}
                  >
                    previous step
                  </button>
                </div>
                <div className="col-6 col-sm-6 d-flex justify-content-end">
                  <button
                    className="btn btn-lg"
                    onClick={this.nextStep}
                    style={{
                      width: "100%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      next
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <div className="container text-center mb-5 px-5">
              <div
                id="campaign_progress"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1px",
                  marginBottom: "20px",
                }}
              >
                <li
                  id="step1"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step2"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step3"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  id="step4"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="hide"
                  style={{
                    color: "#120c34",
                    fontSize: "30px",
                    listStyleType: "none",
                  }}
                >
                  •
                </li>
                <li
                  className="active"
                  id="step5"
                  style={{
                    color: "#120c34",
                    fontSize: "24px",
                    listStyleType: "none",
                  }}
                ></li>
              </div>
            </div>
            <h3 className="text-center mb-2">campaign budget and payout</h3>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h3 className="mt-3 text-center" style={{}}>
                    maximum budget
                  </h3>
                  <input
                    type="number"
                    value={this.state.maximumBudget}
                    onChange={(e) =>
                      this.handleInputChange("maximumBudget", e.target.value)
                    }
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                      fontSize: "x-large",
                      width: "100%",
                    }}
                    placeholder="1-10"
                    className="input-box rounded-pill p-2 text-center"
                  />
                  <Errors msg={this.state?.errors?.maximumBudget} />
                </div>
                <div className="col-md-4">
                  <h3 className="mt-3 text-center" style={{}}>
                    maximum audience
                  </h3>
                  <input
                    type="number"
                    value={this.state.maximumAudience}
                    onChange={(e) =>
                      this.handleInputChange("maximumAudience", e.target.value)
                    }
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                      fontSize: "x-large",
                      width: "100%",
                    }}
                    placeholder="1-10"
                    className="input-box rounded-pill p-2 text-center"
                  />
                  <Errors msg={this.state?.errors?.maximumAudience} />
                </div>
                <div className="col-md-4">
                  <h3 className="mt-3 text-center" style={{}}>
                    participants audience size
                  </h3>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      marginBottom: "50px",
                    }}
                  >
                    <input
                      type="number"
                      value={this.state.participantAudienceSizeMIN}
                      onChange={(e) =>
                        this.handleInputChange(
                          "participantAudienceSizeMIN",
                          e.target.value
                        )
                      }
                      style={{
                        border: "#120c34 2px solid ",
                        borderRadius: "25px",
                        fontSize: "x-large",
                        width: "100%",
                      }}
                      placeholder="min"
                      className="input-box rounded-pill p-2 text-center "
                    />
                    <input
                      type="number"
                      value={this.state.participantAudienceSizeMAX}
                      onChange={(e) =>
                        this.handleInputChange(
                          "participantAudienceSizeMAX",
                          e.target.value
                        )
                      }
                      style={{
                        border: "#120c34 2px solid ",
                        borderRadius: "25px",
                        fontSize: "x-large",
                        width: "100%",
                      }}
                      placeholder="max"
                      className="input-box rounded-pill p-2 text-center"
                    />
                  </div>
                  <Errors msg={this.state?.errors?.participantSize} />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-6 text-center px-0">
                  <h3 className="text-center  ">estimated participants</h3>
                  <input
                    type="number"
                    value={this.state.estimatedParticipants}
                    onChange={(e) =>
                      this.handleInputChange(
                        "estimatedParticipants",
                        e.target.value
                      )
                    }
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                      background: "#120c34",
                    }}
                    placeholder="min-max"
                    className="  input-box1 set_btn_font1 set_btn_font rounded-pill p-2 text-center text-light"
                  />
                  <Errors
                    msg={this.state?.errors?.estimatedParticipants}
                    mid={true}
                  />
                </div>
                <div className="col-6 col-sm-6 text-center px-0">
                  <h3 className="text-center  ">cost per target</h3>
                  <input
                    type="number"
                    value={this.state.costPerTarget}
                    onChange={(e) =>
                      this.handleInputChange("costPerTarget", e.target.value)
                    }
                    style={{
                      border: "#120c34 2px solid ",
                      borderRadius: "25px",
                      background: "#120c34",
                    }}
                    placeholder="1-10"
                    className="give_some_margin_top1 set_btn_font1 set_btn_font  input-box1 rounded-pill p-2 text-center text-white"
                  />
                  <Errors msg={this.state?.errors?.costPerTarget} mid={true} />
                </div>
              </div>
            </div>

            <div className="container mb-5">
              <div className="row mt-5">
                <div className="col-6 col-sm-6 d-flex justify-content-start">
                  <button
                    type="button"
                    onClick={this.prevStep}
                    className="give_wid btn bg-transparent btn-lg"
                    style={{
                      color: "#120c34",
                      border: "#120c34 3px solid",
                      borderRadius: "25px",
                    }}
                  >
                    previous step
                  </button>
                </div>
                <div className="col-6 col-sm-6 d-flex justify-content-end">
                  <button
                    className="btn btn-lg"
                    style={{
                      width: "100%",
                      letterSpacing: "-.02em",
                      textTransform: "none",
                      backgroundColor: "#3898ec",
                      backgroundImage:
                        "linear-gradient(47deg,#7d90c9 30%,#6dcaf0)",
                      borderRadius: "25px",
                      marginLeft: "-32px",
                      fontFamily: "sofia-pro,sans-serif",
                      fontSize: "1.2em",
                      fontWeight: 600,
                      lineHeight: 1,
                      transition: "transform .3s",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        lineHeight: "inherit",
                        cursor: "pointer",
                        // backgroundColor: '#3898ec',
                        border: "0",
                        borderRadius: "0",
                        padding: "9px 15px",
                        textDecoration: "none",
                        display: "inline-block",
                      }}
                    >
                      submit
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
    }
  }

  renderModalContent(props) {
    return (
      // <form onSubmit={this.handleSubmit}>
      <form ref={this.formRef} onSubmit={this.handleSubmit}>
        {this.renderForm(props)}
      </form>
    );
  }
  render() {
    if (!this.isLogedin()) {
      // Redirect to /signin if not logged in
      return <Navigate to="/signin" replace />;
    }

    if (this.state.shouldNavigate) {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id"); // Extract `id` from query string

      return <Navigate to={`/campaigns_manage?id=${id}`} />;
    }

    // const selectedInterestNames = this.state?.interests
    //   .map(
    //     (id) =>
    //       this.state.interestOptions.find(
    //         (option) => option.id === parseInt(id)
    //       )?.name
    //   )
    //   .filter((name) => name); // filter out any undefined values if an id is removed

    return (
      <div>
        {this.renderModalContent()}
        <ToastContainer />
      </div>
    );
  }
}
