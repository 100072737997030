import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import Navbar from '../Navbar';

function SignInForm() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const navigate = useNavigate(); // Used for redirecting to another route after login
    // useEffect(() => {
    //     const isLogedin = localStorage.getItem('isLogedin') === 'true';
    //     if (isLogedin) {
    //         navigate('/'); // Redirect if already logged in
    //     }
    // }, [navigate]);
    useEffect(() => {
        const checkLoginStatus = () => {
            const isLogedin = localStorage.getItem('isLogedin') === 'true';
            if (isLogedin) {
                navigate('/'); // Redirect if already logged in
            }
        };
        checkLoginStatus();
    }, [navigate]);
    

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('Form data submitted:', formData);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password
                })
            });

            const responseData = await response.json();
            // console.log('Login Response:', responseData);

            if (response.ok && responseData.status === "SUCCESS") {
                localStorage.setItem('refreshToken', responseData.data.refresh);
                localStorage.setItem('accessToken', responseData.data.access);
                localStorage.setItem('account', responseData.data.account);
                localStorage.setItem('f_log', responseData.data.first_login);
                localStorage.setItem('isLogedin', true);
                
                if (responseData.data.account===2){
                    navigate('/'); // Adjust the redirection path as needed
                }else if(responseData.data.account===1){
                    navigate('/home'); 
                }
            } else {
                alert('Login Failed: ' + (responseData.message || 'Please check your credentials.'));
            }
        } catch (error) {
            console.error('Error logging in:', error);
            alert('An error occurred during login.');
            navigate('/signin');
        }
    };

    return (
        <div >
            <Navbar text={"#101531"} textColor={"white"} photo={"embassy_white_logo.png"} />
            <div className="container-fluid text-center py-5" 
                style={{ background: '#101531', color: 'white', padding: '20px', margin: 'auto', height: '92.1vh', display: 'flex', flexDirection: 'column' }}>
                <h2 className='mb-4'>Login to Embassy!</h2>
                <div className="row justify-content-center">
                    <form className="col-md-3" onSubmit={handleSubmit}>
                        <div className="form-group text-left">
                            <label style={{ textAlign: 'left', fontSize: "1.3rem" }} className="mb-0 ml-2">Email</label>
                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} style={{ borderRadius: "25px" }} required />
                        </div>
                        <div className="form-group text-left">
                            <label style={{ textAlign: 'left', fontSize: "1.3rem" }} className="mb-0 ml-2">Password</label>
                            <input type="password" className="form-control" name="password" value={formData.password} onChange={handleChange} style={{ borderRadius: "25px" }} required />
                        </div>
                        <div className="form-group">
                            <button className='btn btn-lg mt-3' type="submit" style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s' }}>
                                <span style={{ color: '#fff', lineHeight: 'inherit', cursor: 'pointer', padding: '9px 15px', textDecoration: 'none', display: 'inline-block' }}>
                                    Login
                                </span>
                            </button>
                        </div>
                        <div className="form-group">
                            <small className='text-center' style={{ textAlign: 'center', marginTop: '20px', fontWeight: "600" }}>
                                New to embassy?<br />  Click <Link to="/signup" style={{ color: '#A267AC' }}>here</Link> to signup.
                            </small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignInForm;
