import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Library extends Component {
  handlePhotoSelection = (option) => {
    this.setState({ selectedPhotoOption: option });
  };

  handleCampaignSelection = (option, campaignId) => {
    this.setState({ selectedCampaignOption: option });
    // Call fetchContent1 with the selected campaignId
    this.fetchContent1(true, campaignId);
  };
  state = {
    content: [],
    campaigns: [],
    approved: false,
    selectedPhotoOption: "Photos",
    selectedCampaignOption: "Campaigns",
  };

  componentDidMount() {
    this.fetchContent();
    this.fetchCampaigns();
  }

  handleImageError = (event, videoSrc) => {
    // Create a video element to replace the failed image
    const videoElement = document.createElement("video");
    videoElement.setAttribute("controls", "true");
    videoElement.setAttribute("src", videoSrc);
    videoElement.setAttribute(
      "style",
      "height: 65vh; position: relative; z-index: 2;"
    );

    // Replace the image with the video element
    event.target.replaceWith(videoElement);

    // Replace the background image with a video background
    const parentDiv = event.target.closest(".media-container");
    if (parentDiv) {
      parentDiv.style.backgroundImage = "none";
      const backgroundVideoElement = document.createElement("video");
      backgroundVideoElement.setAttribute("autoplay", "true");
      backgroundVideoElement.setAttribute("loop", "true");
      backgroundVideoElement.setAttribute("muted", "true");
      backgroundVideoElement.setAttribute("src", videoSrc);
      backgroundVideoElement.setAttribute(
        "style",
        "position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; z-index: 1; filter: blur(40px);"
      );
      parentDiv.insertBefore(backgroundVideoElement, parentDiv.firstChild);
    }
  };

  fetchContent = async (approved = false) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const query = approved ? "?approved=true" : "";
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content${query}`,
      {
        headers: {
          Authorization: `Bearer ${isLogedin}`,
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      this.setState({ content: result.data.results });
    }
  };

  fetchContent1 = async (approved = false, cam_id) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const query = approved ? "?approved=true" : "";
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content${query}&campaign=${cam_id}`,
      {
        headers: {
          Authorization: `Bearer ${isLogedin}`,
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      this.setState({ content: result.data.results });
    }
  };

  fetchCampaigns = async () => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/dropdown-campaign?campaign_type=3,4`,
      {
        headers: {
          Authorization: `Bearer ${isLogedin}`,
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      this.setState({ campaigns: result.data });
    }
  };
  handleButtonClick = () => {
    const selectElement = document.getElementById("customSelect");
    if (selectElement) {
      selectElement.focus();
      // Create and dispatch a mouse event to open the dropdown
      const event = new MouseEvent("mousedown", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      selectElement.dispatchEvent(event);
      // console.log("clicked")
    }
  };
  handleApprove = async (contentId) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/approve/${contentId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${isLogedin}`,
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      // Handle success (e.g., update the state or show a notification)
      // console.log('Content approved successfully');
      toast.success("Content approved! ");
    } else if (
      result.status === "FAILURE" &&
      result.message === "Content action previously taken"
    ) {
      toast.error("You have already taken an action on this content");
    } else {
      // Handle error
      console.error("Error approving content");
    }
  };

  handleReject = async (contentId) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/reject/${contentId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${isLogedin}`,
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.status === "SUCCESS") {
      // Handle success (e.g., update the state or show a notification)
      // console.log('Content rejected successfully');
      toast.error("Content Rejected! ");
    } else if (
      result.status === "FAILURE" &&
      result.message === "Content action previously taken"
    ) {
      toast.error("You have already taken an action on this content");
    } else {
      // Handle error
      console.error("Error rejecting content");
    }
  };
  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ approved: isChecked }, () => {
      this.fetchContent(isChecked);
    });
  };

  render() {
    const { content, campaigns, approved } = this.state;

    const backButtonStyle = {
      background: "none",
      border: "none",
      padding: 0,
      fontSize: "25px",
      cursor: "pointer",
      marginBottom: "5px",
    };

    const blueContainerStyle = {
      backgroundColor: "rgb(6, 6, 77)",
      color: "white",
      padding: "10px",
      borderRadius: "10px",
    };

    const switchStyle = {
      margin: 0,
    };
    const customSelectStyle = {
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      background: "white",
      border: "none",
      padding: 0,
      margin: 0,
      color: "#120c34",
      width: "100%",
      justifyContent: "center",
      fontWeight: "800",
    };

    const { selectedPhotoOption, selectedCampaignOption } = this.state;
    return (
      <div>
        <div className="bg-white text-primary p-3">
          <button
            className="d-flex align-items-center"
            onClick={() => window.history.back()}
            style={backButtonStyle}
          >
            <i
              className="fa fa-arrow-left mr-1"
              style={{ fontSize: "13px" }}
            ></i>
            back
          </button>
          <div style={{ color: "#120c34" }}>Content Library</div>
        </div>

        <div className="container-fluid" style={blueContainerStyle}>
          <div className="row">
            <div className="col-12 col-md-3  ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <p className="Sw-Choice" style={switchStyle}>
                  All
                </p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={approved}
                    onChange={this.handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
                <p className="Sw-Choice" style={switchStyle}>
                  Approved
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3 text-center ">
              <div class="btn-group content_drop_wid_m content_drop_wid_m1">
                <button
                  class="btn btn-secondary mr-1"
                  type="button"
                  style={{
                    color: "#120c34",
                    width: "100%",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    customSelectStyle,
                    background: "white",
                  }}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {selectedPhotoOption}
                </button>
                <button
                  type="button"
                  class="border-0 bg-white  dropdown-toggle dropdown-toggle-split py-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    fontSize: "x-large",
                  }}
                >
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    Photo's
                  </a>
                  <a className="dropdown-item" href="#">
                    Video's
                  </a>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 text-center">
              <div class="btn-group content_drop_wid_m content_drop_wid_m1">
                <button
                  class="btn btn-secondary mr-1"
                  type="button"
                  style={{
                    color: "#120c34",
                    width: "100%",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    customSelectStyle,
                    background: "white",
                  }}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {selectedCampaignOption}
                </button>
                <button
                  type="button"
                  class="border-0 bg-white  dropdown-toggle dropdown-toggle-split py-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    fontSize: "x-large",
                  }}
                >
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu">
                  {campaigns.map((campaign) => (
                    <a
                      className="dropdown-item"
                      href="#"
                      key={campaign.id}
                      onClick={() => this.fetchContent1(true, campaign.id)}
                    >
                      {campaign.title}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 rounded bg-light p-2 m-1">
            {content.map((item, index) => (
              <div className="col-md-3 col-sm-6 mb-3 px-1" key={index}>
                <div data-toggle="modal" data-target={`#modal-${item.id}`}>
                  <img
                    src={item.image}
                    className="img-fluid rounded"
                    style={{ height: "400px", width: "400px" }}
                    alt="Content"
                    onError={(e) => {
                      e.target.src = "/images/videologo.png";
                      e.target.alt = "Fallback Image";
                    }}
                  />
                </div>

                <div
                  className="modal fade bd-example-modal-lg"
                  id={`modal-${item.id}`}
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      className="modal-content"
                      style={{ borderRadius: "25px" }}
                    >
                      <div
                        className="modal-body p-0"
                        style={{ background: "#120c34" }}
                      >
                        <div style={{ borderRadius: "25px" }}>
                          {/* <img src={item.image} className='img-fluid' alt="" style={{ width: '100%', height: 'auto', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }} /> */}
                          <div style={{ position: "relative" }}>
                            <div
                              className="text-center"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                style={{
                                  zIndex: "10000000000",
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  fontSize: "1.5rem",
                                  color: "#fff",
                                }}
                              >
                                <i
                                  class="fa-solid fa-xmark fa-2x"
                                  style={{ color: "white" }}
                                ></i>
                              </button>
                              <div
                                className="background-image"
                                style={{
                                  backgroundImage: `url(${item.image})`,
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  filter: "blur(40px)",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  zIndex: 1,
                                }}
                              ></div>
                              <img
                                className="original-image"
                                src={item.image}
                                alt="User"
                                style={{
                                  height: "65vh",
                                  position: "relative",
                                  zIndex: 2,
                                }}
                                onError={(event) =>
                                  this.handleImageError(event, item.image)
                                }
                              />
                            </div>
                            <div class="blur-effect"></div>
                            <div class="shade-effect "></div>
                          </div>
                        </div>
                        <h3 className="text-white px-3 py-1">
                          @ {item.creator}
                        </h3>
                        <p className="text-white px-3">{item.caption}</p>
                        <div
                          className="p-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "20%",
                          }}
                        >
                          <div
                            className="card bg-success"
                            style={{ borderRadius: "100%" }}
                            onClick={() => this.handleReject(item.id)}
                          >
                            <div
                              className="card-body bg-danger text-center "
                              style={{
                                borderRadius: "100%",
                                border: "white solid 3px",
                              }}
                            >
                              <i
                                class="fa-solid fa-xmark fa-3x"
                                style={{ color: "#120c34" }}
                              ></i>
                            </div>
                          </div>
                          <div
                            className="card bg-success"
                            style={{ borderRadius: "100%" }}
                            onClick={() => this.handleApprove(item.id)}
                          >
                            <div
                              className="card-body bg-sucess text-center "
                              style={{
                                borderRadius: "100%",
                                border: "white solid 3px",
                              }}
                            >
                              <i
                                class="fa-solid fa-check fa-3x"
                                style={{ color: "#120c34" }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          style={{ borderRadius: "25px" }}
        >
          <div
            className="modal-dialog modal-lg"
            style={{ borderRadius: "25px" }}
          >
            <div className="modal-content " style={{ borderRadius: "25px" }}>
              <div className="modal-body p-0" style={{ background: "#120c34" }}>
                <div style={{ borderRadius: "25px" }}>
                  <img
                    src={"/images/sample1.png"}
                    className=""
                    alt=""
                    style={{
                      width: "100%",
                      height: "64vh",
                      borderTopLeftRadius: "25px",
                      borderTopRightRadius: "25px",
                    }}
                  />
                </div>

                <h3 className="text-white px-3 py-1">@ZZZZ</h3>
                <p className="text-white px-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Aperiam fugiat dignissimos, eveniet quibusdam soluta nostrum
                  dolores temporibus, laboriosam veniam voluptatem, culpa
                  placeat facilis unde. Quidem cupiditate quibusdam voluptatum
                  consequuntur temporibus!
                </p>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    );
  }
}
